@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
@font-face {
    font-family: 'Amble';
    src: url(../../static/media/Amble-Regular.3ae725e7.otf);
}

@font-face {
    font-family: 'Amble-Light';
    src: url(../../static/media/Amble-Light.6bd35ac2.otf);
}

@font-face {
    font-family: 'Amble-Bold';
    src: url(../../static/media/Amble-Bold.dca8f500.otf);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.slick-slide div {
    color: #4d4d4d;
    font-family: 'Amble-Light';
}

.Home .slick-slide {
    overflow: hidden;
}

.autocomplete {
    position: absolute;
    list-style: none;
    text-align: left;
    background: #fff;
    width: 100%;
    top: 60px;
    z-index: 9999999;
    border-radius: 20px;
    padding: 20px;
}

.autocomplete li {
    padding: 10px;
    font-family: 'Amble-light';
    font-size: 13px;
    cursor: pointer
}

.autocomplete li:hover {
    background: lightblue;
    border-radius: 10px;
}

.related-products_add {
    display: flex;
    justify-content: space-between;
}

body {
    background: #F9F9F9;
    position: relative;
}

.container-header {
    position: fixed;
    z-index: 9999999;
    width: 100%;
}

.container {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    background: #FFFFFF;
    padding-bottom: 80px;
}

.container-grid {
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.filter-button {
    display: none;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiInput-underline:before , .MuiInput-underline:after{
    border: none !important;
}

.MuiFormControl-marginNormal {
    margin: 0 !important;
}

.MuiInputAdornment-root {
    position: absolute;
    right: 0;
}

.MuiInputBase-input {
    height: auto !important;
}

.css-26l3qy-menu {
    z-index: 999999 !important;
}

.Map{
    display: block;
}

.Name-category.movil {
    display: none;
}

.Products-Quote .movil svg {
    display: none;
}

button.gm-control-active, .gm-svpc, .gm-style-mtc, a div img {
    display: none;
}

.title h6 {
    font-family: 'Raleway';
    font-size: 13px;
}

.gmnoprint div {
    background: transparent !important;
}

.info-container > div > div > div > div > div  {
    border-radius: 50px
}

.content-login {
    flex-direction: column;  
}

.copy-socials.movil {
    display: none;
}

.no-quote {
    padding: 20px 20px;
    padding-bottom: 80px ;
}

@media only screen and (max-width: 1280px) {
    .Slicks-pm ul.slick-dots {
        right: 50px !important;
    }
    .menu-categories ul {
        display: inline-flex !important;
        overflow-x: scroll !important;
        justify-content: start !important;
    }
    li a.category {
        width: 170px !important;
        margin-left: 40px;
    }
    li a.active-category {
        width: 170px !important;
        margin-left: 40px;
    }
    .form-grid {
        grid-template-columns: repeat(1,1fr) !important;
    }
}

@media only screen and (max-width: 1280px) {
    .Form-container_postulation {
        padding: 40px !important;
    }
}

@media screen and (max-height: 1064px) and (max-width: 1050px) {
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 740px !important;
        left: -125px !important;
    }
    .Rotate-slick.Us.Main.tablet .info-slick {
        top: 890px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 355px !important;
        left: -240px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 515px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl.ls::after {
        top: 595px !important;
        left: -260px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 435px !important;
        left: -380px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 515px !important;
        left: -380px !important;
    }
    .form-container .grid-form_doble , .form-container .grid-form_triple , .grid-form_doble_right{
        grid-template-columns: repeat(1,1fr) !important;
    }
    .form-container .uploader , .form-container .btn-adj {
        grid-column: 1/5 !important;
    }
    .Form-container_postulation h4 {
        font-size: 22px !important;
        width: 100% !important;
    }
    .form-container .btn-send button {
        top: 42px !important;
        position: relative;
    }
    .Rotate-slick::after {
        top: 354px !important;
    }
    .Elipse {
        top: 30px !important;
        left: 80px !important;
        transform: rotate(19.34deg) !important;
        background-repeat: no-repeat;
    }
    .Elipse img {
        width: 210px !important;
        height: 240px !important;
    }
    .biof {
        top: 150px !important;
        left: 140px !important;
    }
    .biof img {
        width: 100px !important;
        height: 70px !important;
    }
    .Footer-pm {
        display: none;
    }
    .container-grid.work .new-slick.work.tablet .card-work {
        height: 400px !important;
    }
    .container-grid.first .info-container p {
        font-size: 12px !important;
    }
    .container-grid-ds-forms ,.img-input-ds.edit  {
        grid-template-columns: repeat(1,1fr) !important;
        grid-gap: 0px !important;
    }
    .grid-container-ds {
        grid-template-columns: repeat(1,1fr) !important;
    }
    .Footer-pm.movil {
        display: block !important;
    }
    .home .container-known .info-known {
        grid-column: 1/8 !important;
        justify-content: flex-start;
        margin-left: 35px !important;
    }
    .img-new_slick_us img ,.img-new_slick_us::after{
        border-radius: 30px !important;
    }
    .Container-sb_pm .sb_pm .header-title p {
        margin-top: 40px !important;
    }
    .Home .content-slick-home {
        height: 95vh !important;
    }
    .makeStyles-paper-1 {
        width: 90% !important;
    }
    .Products-Quote.Search-Products .filter-button , .Products-Quote.Search-Products .pagination {
        display: none;
    }
    .container-grid.clients-page .info-clients  {
        margin-left: 35px !important;
        grid-column: 1/8 !important;
    }
    .container-clients .title-client {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .container-grid.clients-page .info-clients h2 {
        font-size: 22px !important;
    }
    .container-grid.clients-page .info-clients p {
        max-width: 400px !important;
    }
    .container .container-grid.clients-page .info-client-footer {
        margin-left: 35px !important;
        max-width: 90% !important;
    }
    .container .container-grid.clients-page .info-client-footer p ,.info-client-footer p {
        max-width: 100% !important;
    }
    .Known-pm.home .products-known {
        grid-column: 1/8 !important;
        justify-content: flex-start;
    }
    .home .container-known .info-known h3 {
        width: 232px !important;
        line-height: 25px !important;
    }
    .home .container-known .info-known p ,.bioform-pm p, .line-products_pm p, .material-pm p, .use-app_pm p {
        font-size: 14px !important;
    }
    .square-sl-sbl,.square-distributor ,.container-known_video .play-known .square ,.tablet .container-news .square-slick , .wc .square-carbajal 
    ,.card-about .square.us ,.container-grid .play-known .square{
        max-width: 380px;
        max-height: 280px;
        border-radius: 30px !important;
        left: -20px !important;
    }
    .form-wizard .form-wizard.movil .form-wizard-steps li {
        width: 100% !important;
    }
    .form-wizard {
        margin-top: 40px !important;
    }
    .tablet .container-news .square-slick {
        max-width: 300px !important;
        top: 60px !important;
        border-radius: 30px !important;
    }
    .img-video_known .img img ,.img-video_known .img::after ,.img-new_slick img ,.img-distributor::after,.img-distributor_hm img,.img-new_slick::after {
        border-radius: 20px !important;
    }
    .footer-client_main h6 {
        font-size: 12px !important;
    }
    .types-clients svg {
        width: 35px !important;
    }
    .container-grid.clients-page div#industrial, .container-grid.clients-page div#foodservices {
        padding-top: 0px !important;
    }
    .post-sl-sbl .square-sl-sbl {
        left: 20px !important;
        top: 60px;
    }
    .info-clients p, .info-client-footer p {
        font-size: 12px !important;
    }
    .container .container-grid.why .play-button_pm {
        top: 250px !important;
        right: 40px !important;
    }
    .container-grid.first .square-img-card {
        max-width: 380px !important;
        max-height: 280px;
        left: 20px !important;
    }
    .container-grid .play-known .square {
        left: 20px !important;
    }
    .container-grid.pet .info-pet-sbl {
        margin-left: 35px !important;
    }
    .wc .square-carbajal  {
        transform: rotate(-9.08deg) !important;
        top: 30px;
    }
    .About-us_pm .card-about .square.us {
        top: -38px;
        width: 270px !important;
        border-radius: 30px !important;
        left: -20px !important;
    }
    .flex-pet-sbl {
        flex-wrap: wrap;
        text-align: left !important;
    }
    .info-pet-sbl {
        right: 0px !important;
    }
    .flex-pet-sbl > div {
        padding: 0px;
        margin-left: 0px !important;
        font-size: 12px !important;
        text-align: center !important;
    }
    .flex-pet-sbl > div svg {
        width: 100px !important;
    }
    .card-body p {
        font-size: 9px !important;
    }
    .container .container-grid.work .info-new_slick {
        margin-left: 35px !important;
    }
    .container-grid.why .play-known .square {
        left: 20px !important;
    }
    .container-grid.tonel .info-container , .container-grid.first .info-container ,
    .container-grid.community .info-container,.container-grid.information .certifications-companies {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .container-grid.information .certifications-companies .info-container {
        right: 0 !important;
    }
    .container-grid.information .certifications-companies .info-container p ,.info-container .title-green-dark-container h6 {
        font-size: 14px !important;
        width: 90% !important;
    }
    .container-grid.work .card-work {
        max-width: 400px !important;
    }
    .container-grid.work .slick-slide img {
        width: 100%;
    }
    .container-grid.elipse h6 {
        margin-top: 30px !important;
        font-size: 22px !important ;
        max-width: 300px;
        margin: 0 auto;
    }
    .container-grid.elipse p {
        max-width: 400px;
        margin: 0 auto;
        font-size: 10px !important;
    }
    .container-grid.elipse .play-known.elipse .square {
        left: 25px !important;
    }
    .container-grid.information .play-known.info .square {
        left: 20px !important;
    }
    .container-grid.information .certifications-companies {
        justify-content: flex-start !important;
    }
    .container-grid.elipse .info-container.question ,.container-grid.elipse .box-container.question {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        justify-content: flex-start !important;
    }
    .container-grid.elipse .box-container.question {
        margin-top: 50px !important;
        max-width: 380px !important;
    }
    .card-work {
        margin-left: 0px !important;
        max-width: 400px !important;
    }
    .post-pet-sbl.activity .img-new_slick.activity {
        max-width: 300px !important ;
        max-height: 400px !important;
        left: 0px !important;
    }
    .post-pet-sbl.activity .square-pet-sbl.activity {
        max-width: 300px !important ;
        max-height: 400px !important;
    }
    .container-grid.videos .slick-list .square-pet-sbl.activity {
        top: 50px !important;
        left: 40px !important;
    }
    .flex-pet-sbl > div > div {
        margin-bottom: 30px !important;
    }
    .container-grid.clients-page {
        display: block !important;
    }
    .container-grid.videos  {
        padding-top: 0px !important;
    }
    .container-grid.videos .new-slick.work.activity .info-post-activity {
        grid-row: 1/2 !important;
    }
    .container-grid.videos .new-slick.work.activity .slick-slide > div > div { 
        display: block !important;
        margin-left: 0px !important;
    }
    .container-grid.videos .play-button_pm.activity {
        left: 250px !important;
        top: 300px !important;
    }
    .img-about_us .img img ,.img-about_us .img::after{
        border-radius: 30px !important;
    }
    .About-us_pm .img-about_us .img::after {
        max-width: 380px;
        height: 100% !important;
    }
    .info-carbajal.about p {
        font-size: 12px !important;
    }
    .info-about h3 {
        font-size: 16px !important;
        font-family: 'Amble-Bold';
    }
    .Certifications .info-certifications , .Certifications .certifications-companies ,.container-news .info-new_slick {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .Certifications .certifications-companies .companies_us {
        margin-bottom: 0px !important
    }
    .container-news .info-new_slick h6, .container-news .info-new_slick p {
        margin-top: 8px !important;
        margin-bottom: 0px !important;
        max-width: 400px !important;
    }
    .container-news .info-new_slick h6 {
        margin-bottom: 20px !important;
    }
    .img-values {
        padding: 25px !important;
    }
    .img-values div{
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
    h6.title-values {
        font-size: 22px !important;
        font-family: 'Amble-Bold';
        margin-left: 35px;
        justify-content: flex-start;
    }
    .Mision-vision_pm .info-vision, .Mision-vision_pm .info-mision, .Mision-vision_pm .info-promise_value,
    .Mision-vision_pm .info-propose, .Mision-vision_pm .square-mision, .Mision-vision_pm .square-vision, .Mision-vision_pm .square-promise_value,.Mision-vision_pm .square-propose {
        width: 340px !important;
    }
    .card-about .img-about_us {
        left: 0px !important;
    }
    .container-informations {
        padding-bottom: 50px !important;
    }
    .container-known_video .play-known .square {
        left: -35px !important;
    }
    .play-button_pm {
        top: 140px !important;
        right: -50px !important;
    }
    .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
        height: 100% !important;
    }
    .bioform-pm a, .line-products_pm a, .material-pm a, .use-app_pm a {
        display: flex;
        justify-content: center;
    }
    .img-card, .square-img-card.right-up,.News-pm_us .img-new_slick_us,.News-pm_us .img-new_slick,.img-distributor  ,.img-video_known .img ,.tablet .img-new_slick ,.img-carbajal.about ,.card-about .img-about_us {
        max-width: 380px !important;
        max-height: 280px !important;
    }
    .info-sbl {
        max-width: 90% !important;
    }
    .square-img-card {
        border-radius:  30px !important;
    }
    .News-pm_us .new-slick.plant.tablet .img-new_slick ,.container-news .img-new_slick_us {
        left: -10px !important;
    }
    .new-slick.plant.tablet .square-slick {
        top: 60px !important;
        left: -30px !important;
    }
    .container-news .post-new_slick_us {
        margin-top: 0px !important;
    }
    .img-card img ,.etic .square-img-card.right-up {
        border-radius: 30px !important;
    }
    .page-new {
        overflow: hidden;
    }
    .Map_pm .img-map_pm {
        grid-column: 1/11 !important;
    }
    .Map_pm .img-map_pm img {
        max-width: 70% !important;
    }
    .title-white-container h6 {
        font-size: 22px !important;
    }
    .etic-pro .info-container {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .container-grid.sbl .info-pm {
        margin-left: 35px !important;
    }
    .etic-pro .info-container p {
        font-size: 12px !important;
    }
    .container-grid .etic .square-img-card.right-up {
        right: 17% !important;
    }
    .Footer-news_us .info-footer_news_us p {
        max-width: 90% !important;
    }
    .Footer-news_us .info-footer_news_us ,.container-grid.why .info-pm{ 
        grid-column: 1/8 !important;
        margin-left: 35px;
    }
    .About-us_pm .info-about p ,.container-grid.why .info-pm p {
        max-width: 85% !important;
    }
    .container-grid.why .square {
        left: 20px !important;
        border-radius: 30px !important;
    }
    .img-carbajal.about {
        margin-left: 50px !important;
    }
    .info-footer_news_us p {
        font-size: 12px !important;
    }
    .img-distributor img, .img-video_known .img img {
        max-height: 100% !important;
    }
    .Known-pm.home .container-products_pm img  {
        max-width: 184px !important;
        max-height: 144px !important;
        border-radius: 20px !important;
    }
    .line-products_pm::after {
        height: 100% !important;
        top: 2px !important;
    }
    .info-known_our h4 ,.info-post-activity p{
        font-size: 22px !important;
        width: 90% !important;
        margin: 0 auto;
    }
    .info-pm h6 {
        font-size: 22px !important;
        width: 90% !important;
    }
    .client-info {
        grid-column: 1/8 !important;
    }
    .info-client-footer {
        position: relative !important;
    }
    .filter-shadow {
        border-radius: 20px !important;
    }
    .material-pm::after {
        height: 97% !important;
        top: 2px !important;
    }
    .home .products-known {
        margin-top: 30px;
    }
    .home .container-products_pm {
        margin-left: 35px !important;
        grid-gap: 30px !important;
    }
    .home .container-title-new h6 {
        grid-column: 1/7 !important;
    }
    .home .container-known {
        padding-bottom: 40px !important;
    }
    .container-products_business svg {
        width: 79px !important;
        height: 79px !important;
        margin: 0 !important;
    }
    .container-products_business p {
        font-size: 13px !important;
    }
    .Known-pm.home .container-products_business div {
        margin-left: 10px !important;
        text-align: center;
    }
    .Known-pm div .know-lines {
        margin-left: 20px !important;
    }
    .Known-pm.home .container-products_business  {
        width: 100% !important;
        margin-left: 0 !important;
        justify-content: center !important;
        grid-column: 1/8 !important;
        margin-top: 30px !important;
    }
    .container-informations .info-carbajal.about ,.container-informations .info-map_pm.title, .About-us_pm .info-about{
        margin-left: 35px;
        max-width: 90% !important;
        grid-column: 1/8 !important;
    }
    .Certifications .container-news .img-new_slick {
        left: 0px !important;
    }
    .info-carbajal.about p {
        max-width: 85% !important;
    }
    .container-informations.present {
        padding-bottom: 0px !important;
    }
    .container-grid_img {
        margin-top: 20px !important;
    }
    .container-informations .info-map_pm.title span {
        font-size: 18px !important;
        line-height: 20px !important;
    }
    .container-informations .info-map_pm.title h6 ,.info-container .title-green-dark-container h6 {
        font-size: 22px !important;
    }
    .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
        border-radius: 20px !important;
    }
    .container-title-new h6 {
        grid-column: 1/8 !important;
        font-size: 22px !important;
        width: 204px !important;
    }
    .info-known h3 {
        font-size: 22px !important;
    }
    .options-pm-rs {
        grid-column: 10/16 !important;
        justify-content: flex-end !important;
        margin-right: 15px !important;
    }
    .close-movil {
        position: relative;
        top: -6px;
        left: 2px;
    }
    .container-header-rs .Logo-pm ,.img-carbajal.about{
        grid-column: 1/8 !important;
    }
    .info-slick {
        top: 650px !important;
        left: 0px !important;
        transform: rotate(19deg) !important;
    }
    .info-slick h2 {
        font-size: 30px !important;
        width: 223px !important;
    }
    .info-slick p {
        font-size: 14px !important;
    }
    .Rotate-slick::after, .Rotate-slick::after {
        min-height: 15% !important;
        background-size: unset !important;
        min-width: 90% !important;
    }
    .Rotate-slick::before {
        left: -23px !important;
        height: 144vh !important;
        /* background-color: rgba(0, 82, 134, 0.4) !important; */
        transform: rotate(19deg) !important;
    }
    .Rotate-slick {
        height: 87vh !important;
        left: -80px !important;
        /* top: -380px !important; */
        top: -380px !important;
        transform: rotate(-19deg) !important;
        border-radius: 60px !important;
    }
    .Rotate-slick::after {
        left: -28px !important;
        top: 300px !important;
        background-size: unset !important;
        transform: rotate(19deg) !important;
    }
    .info-sbl h4 {
        max-width: 90% !important;
    }
    .Container-sb_pm h2 {
        max-width: 90% !important;
    }
    .models-sb {
        padding: 30px !important;
    }
    .container-grid.first {
        margin-top: 60px !important;
    }
    .container-grid .info-informs p , .container-grid .info-informs, .informs-pdfs ,.info-informs h2{
        max-width: 90% !important;
    }
    .info-container .title-green-container h6 {
        font-size: 22px !important;
    }
    .container-grid.community {
        margin-top: 60px !important;
    }
    summary {
        font-size: 10px !important;
    }
    .text-new {
        font-size: 12px !important;
        margin-bottom: 10px;
    }
    .info-about p {
        font-size: 12px !important;
    }
    summary svg {
        width: 30px !important;
        padding: 6px !important;
    }
    .Radius {
        height: 80vh !important;
        left: -110px !important;
        transform: rotate(-19deg) !important;
        border-radius: 60px !important;
    }
    .tree-pm.banner img {
        top: 140px !important;
        left: -50px !important;
        width: 150px !important;
    }
    .content-slick-home {
        height: 85vh !important;
    }
    .container-distributor .info-distributor_hm, .container-distributor .img-distributor_hm {
        grid-column: 1/8 !important;
    }
    .container-distributor .info-distributor_hm h3 {
        font-size: 22px !important;
    }
    .companies-second_us .img-company-gplast,.companies_us .img-company-sgiso,.companies_us .img-company-sgs ,.companies-second_us .img-company-gp ,.companies-second_us .img-company-gpl ,.companies-second_us .img-company-gpw ,.companies_us .img-company-abe, .companies-second_us .img-company-abe, .companies-second_us .img-company-gptw21 {
        width: 57px !important;
        height: 56px !important;
        margin-right: 20px !important;
    }
    .companies-second_us .img-company-gptw21 {
        width: 47px !important;
        height: 80px !important;
        margin-right: 20px !important;
        right: 0px !important;
    }
    .companies_us .img-company-brc {
        width: 78px !important;
        height: 77px !important;
        top: 10px !important;
    }
    .container-clients {
        padding-top: 50px !important;
    }
    .Clients-pm .slick-track .img-clients .img-client_slick ,.client-slick .slick-track .img-clients .img-client_slick {
        width: 98px !important;
        height: 98px !important;
    }
    .Home {
        overflow:  hidden !important;
    }
    .Clients-pm {
        overflow-y:  hidden !important;
    }
    .client-slick .slick-slide .img-client_slick img {
        width: 45px !important;
    }
    .container-news .img-new_slick_us::after {
        height: 100% !important;
    }
    .container-news .img-new_slick_us img {
        height: 100% !important;
    }
    /* Header */
    .header-rs {
        position: fixed !important;
    }
    /* Titulos */
    .container-detail_new h2,.info-certifications h6 ,.info-new_slick h4,.title-client h4 ,.info-footer_news_us h6,.Container-sb_pm h2 {
        font-size: 22px !important;
    }
    .container-detail_new h2 {
        max-width: 85% !important;
    }
    .info-detail .text {
        padding: 20px 0px !important;
    }
    .Detail-new_pm {
        height: 35vh !important;
    }
    .container-detail_new.news-search .slick-dots {
        width: 40% !important;
    }
    .newpaper-gallery {
        margin-left: 0px !important;
        max-width: 90%;
        margin: 0 auto !important;
    }
    .info-certifications span ,.info-new_slick h6, .certifications-companies h6.companies-title, .certifications-companies h6.companies-title_second,
    .info-footer_news_us div.span {
        font-size: 16px !important;
    }
    .info-footer_news_us div.span {
        max-width: 90% !important;
    }
    .info-certifications p ,.info-new_slick p{
        font-size: 12px !important;
        max-width: 90% !important;
    }
    .title-blue-container h3 {
        width: 90% !important;
    }
    .info-product_bf h4 ,.info-product_bf_2 h4,.info-product_bf_3 h4 ,.info-product_bf_4 h4 {
        font-size: 22px !important;
        max-width: 60% !important;
    }
    .populate-slick .slick-next::before, .populate-slick .slick-prev::before {
        display: none;
    }
    /* Banners */ 
    .Slicks-pm.Us .Radius.Us {
        height: 40vh !important;
        border-radius: 60px !important;
        background: #D1EBFC !important;
        left: -40px !important;
        top: -240px !important;
        transform: rotate(-15deg) !important;
    }
    .Slicks-pm .info-slick.Us {
        top: 850px !important;
    }
    .img-footer_new_us svg, .img-footer_new_us img, .Container-sb_pm .header-title p {
        max-width: 90% !important;
        margin: 0 auto !important;
    }
    .img-footer_new_us {
        display: flex;
    }
    .etic-pro p {
        width: 90% !important;
    }
    .Container-sb_pm {
        padding-top: 20px !important;
    }
    .container-grid .title-container {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        font-size: 22px !important;
    }
    .container-grid .info-informs ,.informs-pdfs {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .informs-pdfs {
        margin-top: 40px !important;
    }
    .info-informs h2 ,.info-sbl h4{
        font-size: 22px !important;
    }
    .info-informs p {
        max-width: 400px !important;
    }
    .container-grid .info-sl-sbl {
        padding: 0px !important;
        margin-left: 30px !important;
        justify-content: flex-start;
    }
    .info-sl-sbl p {
        font-size: 12px !important;
        max-width: 400px !important;
    }
    .box-informs-pdf {
        width: 90% !important;
    }
    .objetives {
        justify-content: center !important;
    }
    .objectives-sosten {
        margin: 30px 10px !important;
    }
    .models-sb {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .Container-sb_pm .header-title p {
        text-align: left !important;
    }
    .info-slick p, .info-slick_us p, .info-slick_sb p, .info-slick_wwu p, .info-slick_clts p, .info-slick_news p {
        font-family: 'Amble-Bold' !important;
        width: 267px !important;
    }
    .Rotate-slick.Us {
        border-radius: 60px !important;
        top: -680px !important;
        left: -100px !important;
        transform: rotate(-19deg) !important;
        height: 98vh !important;
    }
    .news-search h2 {
        text-align: left !important;
        margin-left: 35px;
        font-size: 22px !important;
    }
    .gallery-title {
        font-size: 12px !important;
    }
    .news-search .text {
        display: none !important;
    }
    .search-date ,.container-view_journalists {
        max-width: 90% !important;
    }
    .container-detail {
        padding: 0px !important;
    }
    .container-detail_product .detail-info {
        margin-left: 35px !important;
    }
    .Detail-Product_pm.movil .img-detail_product {
        justify-content: flex-start !important;
        right: 160px !important;
    }
    .container-detail_product .detail-info {
        padding-top: 20px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        left: 180px !important;
        top: 40px !important;
        max-width: 340px !important;
        max-height: 340px !important;
    }
    .container-detail_product  {
        padding-bottom: 20px !important;
    }
    .guide-detail_product {
        padding: 30px !important;
    }
    .products-default > div {
        margin-bottom: 0px !important;
    }
    .products-default h5 {
        width: 100% !important;
        left: -20px !important;
    }
    .pagination {
        padding: 10px 0 !important;
    }
    .Detail-Product_pm.movil .square-products {
        min-width: 300px !important;
        min-height: 300px !important;
    }
    .container-view_journalists {
        grid-template-columns: repeat(1,1fr) !important;
    }
    .info-view_journalists ,.img-view_journalists {
        grid-column: 1/2 !important;
        width: 100% !important;
        height: 180px;
    }
    .img-view_journalists img , .img-view_journalists::after{
        border-top-left-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
    }
    .container-gallery {
        grid-template-columns: repeat(2,1fr) !important;
        max-width: 90% !important;
        grid-gap: 20px !important;
    }
    .tree-new.contact-info img , .tree-new.contact img{
        display: none;
    }
    .Distributor-search  {
        padding-bottom: 8px !important;
    }
    .Distributor-search .Search  .Search-container.distributor{
        grid-column: 2/11;
        max-width: 95% !important;
        padding-bottom: 0px !important;
    }
    .container-grid.distributor .info-container ,.container-grid .ubications {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        max-width: 400px !important;
    }
    .Menu-Category_pm .Search.product button.btn-back {
        display: none !important;
    }
    .Container-Products_Quote_pm {
        grid-template-rows: repeat(4,330px) !important;
        max-width: 90%;
        margin: 0 auto;
    }
    .Products-populate_pm h4 {
        font-size: 22px !important;
    }
    .Products-Quote_1 , .Products-Quote_2 ,.Products-Quote_3 ,.Products-Quote_4{
        grid-column: 1/9 !important;
        border-radius: 20px !important;
    }
    .makeStyles-paper-1 {
        max-width: 90% !important;
    }
    .Sidebar-Material_Quote {
        display: none;
    }
    .Products-Quote {
        grid-column: 1/12 !important;
    }
    .square-products, .products-default .square-products {
        max-width: 120px !important;
        max-height: 120px !important;
        border-radius: 26.8997px !important;
    }
    .products-default {
        margin-left: 35px !important;
    }
    .products-default .product {
        justify-content: flex-start !important;
        max-width: 200px !important;
        max-height: 200px !important;
    }
    .products-default .product .img-product {
        max-width: 116px !important;
        max-height: 116px !important;
    }
    .img-card::after  ,.img-card{
        border-radius: 30px !important;
    }
    .container-filter_pm {
        border-radius:  0px !important;
    }
    .products-default h5 {
        width: 100%;
    }
    .Name-category {
        display: none;
    }
    .Count-products {
        grid-column: 1/7 !important;
    }
    .Count-products::after {
        display: none;
    }
    .product > a {
        top: 90px !important;
        font-size: 9px !important;
        right: 20px !important;
    }
    .Products-Quote {
        padding: 20px 0px !important;
    }
    .Search .Search-container {
        grid-column: 2/11 !important;
    }
    .info-container > div > div > div > div > div {
        border-radius: 0 !important;
    }
    .container-grid .info-container.map-distributor {
        grid-column: 1/8 !important;
    }
    .container-grid.distributor .title-blue-container h3{
        font-size: 22px !important;
    }
    .footer-contact .container-grid {
        grid-gap: 20px !important;
    }
    .form-contact h2 {
        font-size: 22px !important;
    }
    .marker-contact , .call-contact{
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        justify-content: flex-start !important;
    }
    .info-view_journalists h2 {
        margin-left: 0px !important;
    }
    .Rotate-slick.Us::after {
        transform: rotate(19deg) !important;
        left: -450px !important;
        top: 440px !important
    }
    .filter-button {
        display: block;
        max-width: 70%;
        margin: 0 auto;
        margin-top: 50px;
    }
    .filter-button button {
        width: 100%;
        display: flex;
        justify-content: center;
        border: 1px solid #4D4D4C;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 13px;
        background: #fff;
    }
    .products-populate img {
        position: relative;
        top: -30px !important;
        left: 5px !important;
    }
    .products-populate h5 {
        position: relative;
        top: -0px !important
    }
    .makeStyles-paper-2 {
        max-width: 90% !important;
    }
    .makeStyles-paper-2 .Materials-sidebar_pm {
        margin-top: 0px !important;
    }
    div[role="presentation"] {
        overflow: auto;
    }
    .makeStyles-paper-2 .close-modal-filter {
        display: flex;
        justify-content: flex-end;
    }

    .makeStyles-paper-2 .line {
        border-bottom: 1px solid rgba(118, 118, 118, 0.55);
    }
    .Products-Quote .movil {
        display: flex;
    }
    .card-body h6 {
        font-size: 12px !important;
    }
    .Products-Quote .movil svg {
        margin-left: 34px;
        display: block;
    }
    .Products-Quote p.path {
        margin-bottom: 0px !important;
        margin-left: 10px;
    }
    .Name-category.movil {
        display: block;
    }
    .Name-category.movil p {
        color: rgb(0, 159, 227);
        font-size: 16px;
        margin-left: 24px;
        margin-bottom: 10px !important;
    }
    .info-box-quote {
        grid-column: 1/8 !important;
    }
    .info-box-quote .info h3 {
        font-size: 14px !important;
    }
    .info-box-quote .img-quote {
        margin-left: 0px !important;
        max-width: 130px !important;
    }
    .delete-quote {
        grid-column: 8/9 !important;
    }
    .container-dates .container-dates-title ,.container-form-dates{
        background: transparent;
        box-shadow: none;
        border-radius: 0px !important;
    }
    .form-wizard .form-wizard-previous-btn , .container-quotes-products.step-final {
        display: none;
    }
    button.send-cart.complete {
        background: #32711E !important;
    }
    form.form-quote {
        margin-top: 0;
    }
    .form-wizard .form-group {
        text-align: center !important;
    }
    .form-wizard {
        overflow: hidden !important;
    }
    .form-wizard .form-wizard-steps {
        margin-top: 50px !important;
    }
    .delete-quote button span {
        display: none !important;
    }
    .quote-count ,.box-quote {
        border-radius: 0px !important;
    }
    /* Footer */
    .container-footer, .copy-socials {
        padding: 0px !important;
        margin-bottom: 0px !important;
    }
    .copy-socials {
        flex-direction: row-reverse;
    }
    .copy-socials .copy-right div.span {
        display: none;
    }
    .Footer-pm .logo {
        margin-bottom: 30px;
        padding: 20px 20px !important;
        border-bottom: 1px solid #a8a8a8;
    }
    .Footer-pm .declaration , .Footer-pm .information ,.Footer-pm .contact{
        margin-bottom: 23px !important;
        border-bottom: 1px solid #a8a8a8;
        padding: 0px 20px 10px 20px;
    }
    .Footer-pm hr {
        display: none;
    }
    .container-footer .title ,.container-footer h6 {
        width: 100% !important;
    }
    .contact .info-contact div, .declaration .info-contact div, .information .info-contact div {
        margin-top: 10px !important;
    }
    .Footer-pm .logo , .Footer-pm .declaration , .Footer-pm .information , .Footer-pm .contact{
        grid-column: 1/10 !important;
        justify-content: flex-start !important;
    }
    .copy-socials.movil {
        display: block;
        text-align: center;
    }
    .copy-socials.desktop {
        display: none;
    }
    .copy-socials.movil .copy-right {
        justify-content: center !important;
        padding: 0px 0px 10px 0px;
        border-bottom: 1px solid #a8a8a8;
    }
    .copy-socials.movil .copy {
        padding: 20px;
    }
    .title-detail_product h4 {
        font-size: 22px !important;
    }
}


@media screen and (max-height: 896px) and (max-width: 450px) {
    .Rotate-slick.Us.Wu.ls::after {
        top: 450px !important;
        left: -450px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 450px !important;
        left: -550px !important;
    }
    .info-slick {
        top: 640px !important;
        left: 0px !important;
    }
    .Home .content-slick-home {
        height: 87vh !important;
    }
    .container-known_video {
        padding-bottom: 50px;
    }
    .Known-pm.home .container-products_pm img {
        max-width: 144px !important;
        max-height: 104px !important;
    }
    .bioform-pm p, .line-products_pm p, .material-pm p, .use-app_pm p {
        top: 40px !important;
    }
    .container-products_business svg {
        width: 59px !important;
        height: 59px !important;
    }
    .square-img-card.right-up,.img-card, .square-img-card.right-up, .News-pm_us .img-new_slick_us, .News-pm_us .img-new_slick, .img-distributor, .img-video_known .img, .tablet .img-new_slick, .img-carbajal.about, .card-about .img-about_us {
        max-width: 320px !important;
        max-height: 220px !important;
    }
    .container-grid.first .square-img-card,.square-sl-sbl, .square-distributor, .container-known_video .play-known .square, .tablet .container-news .square-slick, .wc .square-carbajal, .card-about .square.us, .container-grid .play-known .square {
        max-width: 320px !important;
        max-height: 220px !important;
    }
    .tablet .container-news .square-slick {
        max-width: 280px !important;
    }
    .info-new_slick ,.title-container {
        max-width: 90% !important;
    }
    .Rotate-slick.Us {
        height: 110vh !important;
    }
    .marker-contact, .call-contact {
        margin-left: 10px !important;
    }
    .Slicks-pm .info-slick.Us {
        top: 800px !important;
    }
    .pm-inf_sk.ma svg {
        width: 50px !important;
        height: 58px !important;
    }
    .img-values svg {
        width: 50px !important;
        height: 58px !important;
    }
    .footer-dc > div {
        margin-top: 10px;
        font-size: 14px !important;
    }
    .Mision-vision_pm .info-vision, .Mision-vision_pm .info-mision, .Mision-vision_pm .info-promise_value, .Mision-vision_pm .info-propose, .Mision-vision_pm .square-mision, .Mision-vision_pm .square-vision, .Mision-vision_pm .square-promise_value, .Mision-vision_pm .square-propose {
        max-width: 260px !important;
        max-height: 250px !important;
    }
    .Products-Quote .products-default {
        grid-template-columns: repeat(1,1fr) !important;
        text-align: -webkit-center !important;
    }
    .objectives-sosten svg {
        width: 50px !important;
        height: 58px !important;
    }
    .info-sl-sbl p {
        max-width: 300px !important;
    }
    .play-known .img-distributor img,  .play-known .img-video_known .img img {
        height: 100% !important;
    }
    .card-work ,.container-grid.work .card-work{
        max-width: 320px !important;
        max-height: 400px !important;
    }
    .container-grid.elipse .box-container .container-accordion {
        max-width: 80% !important;
    }
    .card-body {
        padding: 20px 10px 20px 10px !important;
    }
    .new-slick.work.sbl img {
        height: 390px !important;
    }
    .Container-sb_pm .sb_pm .header-title p {
        font-size: 12px !important;
    }
    .new-slick.work.tablet.xs .card-body .text {
        margin-bottom: 0px !important;
    }
    .container-grid.work .new-slick.work.tablet.xs .card-work {
        height: 340px !important;
    }
    .container-grid.work .new-slick.work.tablet.xs .card-body {
        top: -133px !important;
    }
    .card-body {
        top: -152px !important;
    }
    .info-informs p {
        font-size: 12px !important;
    }
    .box-informs-pdf {
        padding: 0px 10px !important;
    }
    .box-informs-pdf p {
        font-size: 10px !important;
    }
    .box-informs-pdf svg {
        width: 30px !important;
    }
    .post-pet-sbl.activity .img-new_slick.activity ,.post-pet-sbl.activity .square-pet-sbl.activity {
        max-width: 250px !important;
        max-height: 300px !important;
    }
    .container-grid.videos .play-button_pm.activity {
        top: 250px !important;
        left: 150px !important;
    }
    .container .container-grid.why .play-button_pm {
        top: 200px !important;
        right: 10px !important;
    }
    .container .container-grid.clients-page .info-client-footer {
        max-width: 100% !important;
    }
    .Known-pm.home .container-products_business div {
        max-width: 90% !important;
    }
}

@media screen and (max-height: 896px) and (max-width: 430px) {
    .Products-populate_pm .products-default {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .play-button_pm {
        width: 100px !important;
        height: 100px !important;
    }
}

@media screen and (max-height: 780px) and (max-width: 484px) {
    .Home .content-slick-home.xs {
        height: 62vh !important;
    }
    .Rotate-slick.Us.xs {
        height: 115vh !important;
    }
    .Rotate-slick.Us.xs::after {
        background-position: 0px -80px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 420px !important;
        left: -300px !important;
    }
    .Rotate-slick.Us.Wu.ls::after {
        top: 520px !important;
        left: -400px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 520px !important;
        left: -400px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 520px !important;
        left: -480px !important;
    }
}

@media screen and (max-height: 896px) and (max-width: 414px) {
    .biof {
        top: 130px !important;
        left: 120px !important;
    }
    .info-slick {
        top: 600px !important;
        left: 0px !important;
    }
    .info-slick.second {
        top: 530px !important;
        left: 10px !important;
    }
    .Elipse {
        top: 0px !important;
    }

    .biof img {
        width: 100px !important;
        height: 70px !important;
    }
    .Home .content-slick-home {
        height: 64vh !important;
    }
    .Detail-Product_pm.movil .square-products {
        min-width: 210px !important;
        min-height: 210px !important;
    }
    .container-gallery {
        grid-template-columns: repeat(1,1fr) !important;
    }
    .gallery-item {
        grid-column: 1/2 !important;
    }
    .img-detail_product {
        height: 250px !important;
    }
    .container-detail_product .square-products {
        right: 0px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-width: 280px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-height: 250px !important;
    }
    .Known-pm.home .container-products_business  div p.know-lines {
        margin-left: 10px !important;
    }
}

@media screen and (max-height: 667px) and (max-width: 375px) {
    .Home .content-slick-home.md.movil {
        height: 60vh !important;
    }
    .info-slick {
        top: 580px !important;
        left: 0px !important;
    }
    .Elipse.movil {
        left: 70px !important;
    }
    .Elipse.movil img {
        width: 200px !important;
        height: 200px !important;
    }
}

@media screen and (max-height: 872px) and (max-width: 375px) {
    .Rotate-slick.Us.ls {
        height: 108vh !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 700px !important;
        left: -125px !important;
    }
    .Rotate-slick.first .info-slick {
        top: 600px !important;
        left: -10px !important;
    }
    .Rotate-slick.first {
        top: -335px !important;
    }
}

@media screen and (max-height: 568px) and (max-width: 320px) {
    .Rotate-slick.ss::after {
        height: 88vh !important;
    }
    .Rotate-slick.Us.Main.ls::after {
        top: 220px !important;
        left: -420px !important;
    }
    .info-slick.second {
        top: 450px !important;
        left: 10px !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 460px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 220px !important;
        left: -300px !important;
    }
    .Rotate-slick.Us.Wu.ls::after {
        top: 320px !important;
        left: -420px !important;
    }
    .Rotate-slick.Us.Cl.ls::after {
        top: 520px !important;
        left: -200px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 320px !important;
        left: -400px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 320px !important;
        left: -550px !important;
    }
    .Home .content-slick-home.movil.ss {
        height: 90vh !important;
    }
    .Elipse.movil {
        top: 0px !important;
        left: 60px !important;
    }
    .Elipse.movil img {
        width: 180px !important;
        height: 180px !important;
    }
    .biof {
        top: 90px !important;
        left: 110px !important;
    }

    .biof img {
        width: 90px !important;
        height: 60px !important;
    }
    .Rotate-slick.Us.movil {
        height: 130vh !important;
    }
}

@media screen and (max-height: 851px) and (max-width: 380px) {
    .Home .content-slick-home {
        height: 89vh !important;
    }
    .Detail-Product_pm.movil .square-products {
        min-width: 210px !important;
        min-height: 210px !important;
    }
    .img-detail_product {
        height: 250px !important;
    }
    .container-detail_product .square-products {
        right: 0px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-width: 280px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-height: 250px !important;
    }
    .Known-pm.home .container-products_business  div p.know-lines {
        margin-left: 0px !important;
    }
}


@media screen and (max-height: 1000px) and (max-width: 375px) {
    .Elipse.movil {
        top: 10px !important;
        left: 75px !important;
    }
    .Elipse.movil img {
        width: 180px !important;
        height: 180px !important;
    }
    .biof {
        top: 100px !important;
        left: 120px !important;
    }
    .biof img {
        width: 90px !important;
        height: 60px !important;
    }
    .Known-pm.home .container-products_pm img {
        max-width: 126px !important;
    }
    .container-products_business {
        display: block !important;
        grid-column: none !important;
    }
    .button-cat button, .button-cat a {
        max-width: 60% !important;
        margin: 0 auto !important;
    }
    .Known-pm .container-known_business p.know-lines.movil {
        margin-left: 0px !important;
        margin-bottom: 10px !important;
    }
    .Known-pm .container-known_business div {
        margin-top: 20px !important;
    }
    .info-carbajal img {
        max-width: 80% !important;
    }
    .img-about_us .img img,.square-img-card.right-up, .img-card, .square-img-card.right-up, .News-pm_us .img-new_slick_us, .News-pm_us .img-new_slick, .img-distributor, .img-video_known .img, .tablet .img-new_slick, .img-carbajal.about, .card-about .img-about_us {
        max-width: 260px !important;
        max-height: 160px !important;
    }
    .container-grid.first .square-img-card, .square-sl-sbl, .square-distributor, .container-known_video .play-known .square, .tablet .container-news .square-slick, .wc .square-carbajal, .card-about .square.us, .container-grid .play-known .square{
        max-width: 260px !important;
        max-height: 160px !important;
        top: 55px !important;
    }
    .container-known_video .play-known .square {
        top: -10px !important;
    }
    .play-button_pm {
        top: 40px !important;
        right: -30px !important;
    }
    .wc .square-carbajal  {
        top: 20px !important;
    }
    .card-about .square.us {
        top: -20px !important;
    }
    .container-vision, .container-mision, .container-promise-value, .container-propose {
        margin-left: 56px !important;
    }
    .container-grid .play-known .square {
        top: 80px !important
    }
    .container-grid.sbl .play-known .play-button_pm {
        top: 140px !important;
    }
    .card-work, .container-grid.work .card-work {
        max-width: 280px !important;
        max-height: 360px !important;
    }
    .container {
        padding-bottom: 70px !important;
    }
    .container-grid.clients-page div#exportation,  .container-grid.clients-page div#agroindustrial{
        padding-top: 0px !important;
    }
    .client-info {
        position: relative;
        top: -40px !important;
    }
    .container .container-grid.why .play-button_pm {
        top: 150px !important
    }
    .info-post-activity p {
        max-width: 60% !important;
        margin-left: 10px !important;
    }
}

@media screen and (max-height: 838px) and (max-width: 414px) {
    .Home .content-slick-home {
        height: 90vh !important;
    }
}

@media screen and (max-height: 800px) and (max-width: 600px) {
    .Rotate-slick.Us {
        height: 115vh !important;
    }
}

@media screen and (max-height: 736px) and (max-width: 414px) {
    .Rotate-slick.Us {
        height: 115vh !important;
    }
    .Elipse {
        left: 75px !important;
    }
    .Slicks-pm .info-slick.Us {
        left: 0px !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 690px !important;
        left: -125px !important;
    }
    .Rotate-slick.first {
        top: -335px !important;
    }
}

@media screen and (max-height: 851px) and (max-width: 393px) {
    .Home .content-slick-home.movil {
        height: 87vh !important;
    }
    .Rotate-slick.xs::after {
        background-size: contain !important;
    }
}

@media screen and (max-height: 667px) and (max-width: 375px) {
    .slick-list .Rotate-slick.Us {
        top: -600px !important;
    }
    .Rotate-slick.Us::before {
        min-height: 164vh !important;
    }
    .Slicks-pm.Us .slick-slider.slick-initialized .slick-slide {
        height: 55vh !important;
    }
    .Slicks-pm .Rotate-slick.Us .info-slick.Us {
        top: 700px !important;
    }
    .Slicks-pm .Rotate-slick.Us::after {
        background-position: top !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 590px !important;
        left: -125px !important;
    }
    .Rotate-slick.Us.ls {
        height: 110vh !important;
    }
    .Rotate-slick.Us.Main.ls::after {
        top: 230px !important;
        left: -550px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 240px !important;
    }
    .Rotate-slick.Us.Cl.ls::after {
        top: 430px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 330px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 330px !important;
        left: -550px !important;
    }
}

@media screen and (max-height: 740px) and (max-width: 360px) {
    .Home .content-slick-home.movil {
        height: 87vh !important;
    }
    .Rotate-slick.xs::after {
        background-size: contain !important;
    }
}

@media screen and (max-height: 656px) and (max-width: 320px) {
    .Home .content-slick-home.movil {
        height: 87vh !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 460px !important;
        left: -110px !important;
    }
    .Rotate-slick.Us.ls {
        height: 122vh !important;
    }
    .Slicks-pm .Rotate-slick.Us .info-slick.Us {
        top: 550px !important;
        left: 20px !important;
        max-width: 40% !important;
    }
    .info-slick {
        top: 550px !important;
        left: -10px !important;
    }
    .Rotate-slick::before {
        height: 160vh !important;
    }
    .Rotate-slick::after {
        height: 86vh !important;
    }
    .img-carbajal.about ,.container-grid.first .square-img-card, .square-sl-sbl, .square-distributor, .container-known_video .play-known .square, .wc .square-carbajal, .card-about .square.us, .container-grid .play-known .square {
        max-width: 220px !important;
        top: -20px !important;
    }
    .tablet .container-news .square-slick {
        max-width: 220px !important;
    }
    .container-vision, .container-mision, .container-promise-value, .container-propose {
        margin-left: 40px !important;
    }
    .Mision-vision_pm .info-vision, .Mision-vision_pm .info-mision, .Mision-vision_pm .info-promise_value, .Mision-vision_pm .info-propose, .Mision-vision_pm .square-mision, .Mision-vision_pm .square-vision, .Mision-vision_pm .square-promise_value, .Mision-vision_pm .square-propose {
        width: 245px !important;
    }
    .wc .square-carbajal {
        top: 20px !important;
    }
    .post-sl-sbl .square-sl-sbl {
        top: 50px !important
    }
    .container-grid .play-known .square {
        top: 90px !important;
    }
    .footer-dc {
        padding-top: 0px !important;
        padding-bottom: 50px !important;
    }
    .container-header-rs .Logo-pm {
        grid-column: 1/10 !important;
    }
    .container-grid.first .square-img-card {
        top: 50px !important;
        border-radius: 30px !important;
    }
    .Known-pm.home .container-products_pm img {
        max-width: 116px !important;
    }
}

@media screen and (min-height: 656px) and (max-width: 320px) {
    .Known-pm.home .container-products_pm img {
        max-width: 116px !important;
    }
}

@media screen and (max-width: 374px) {
    .Rotate-slick.first .info-slick.second {
        top: 500px !important;
        left: -10px !important;
    }
    .Rotate-slick.first {
        top: -335px !important;
    }
    .Slicks-pm ul.slick-dots {
        width: 24% !important;
        right: 5px !important;
        top: 150px !important;
     }
}
.Header-pm {
    width: 100%;
    background: #FFFFFF;
    height: 78px;
    z-index: 1024;
    box-shadow: 0px 4px 30px rgba(172, 172, 172, 0.15);
}

.Container-header_pm {
    position: relative;
    max-width: 1840px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(15, 1fr);
}

.phones {
    display: flex;
    position: relative;
    padding: 10px;
    color: #4D4D4C;
    grid-column: 5/15;
    font-family: 'Amble';
    font-size: 14px;
    justify-content: flex-end;
}

.phones .icon {
    margin-right: 10px;
}

.Container-header_pm .Logo-pm {
    display: flex;
    justify-content: center;
    grid-column: 1/3;
}

.Container-header_pm .Nav-pm {
    grid-column: 3/12;
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.Container-header_pm .Nav-pm ul {
    display: flex;
    list-style: none;
}

.Container-header_pm .Nav-pm ul li {
    margin-left: 34px;
}

.Container-header_pm .Nav-pm ul li a {
    font-size: 14px;
    color: #4D4D4C;
    font-family: 'Amble-Light';
}

.Container-header_pm .Options-pm_qe {
    grid-column: 12/16;
    display: flex;
    justify-content: center;
    position: relative;
    top: 5px;
}

.Container-header_pm .Options-pm_qe .Quote-btn {
    margin-right: 20px;
}

.Container-header_pm .Options-pm_qe .Ecommerce-btn a,
.container-header-rs .Ecommerce-btn a {
    background: #009FE3;
    border-radius: 30px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    cursor: pointer;
    font-family: 'Amble';
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
}

.container-header-rs .Ecommerce-btn a {
    display: none !important;
}

.Container-header_pm .Options-pm_qe .Ecommerce-btn a svg {
    margin-right: 10px;
}

.Nav-pm ul li {
    position: relative;
    z-index: 1;
}

.Nav-pm ul li a {
    text-decoration: none;
}

.Nav-pm ul li a.active {
    color: #8DB72E;
    font-family: 'Amble-bold';
    transition: 0.6s;
}

.Nav-pm ul li a.active::after {
    content: '';
    width: 100%;
    height: 3px;
    background: #8DB72E;
    position: absolute;
    border-radius: 2px;
    top: 42px;
    left: 0;
    transition: 0.6s;
}

.menus {
    display: flex;
    list-style: none;
}

.menu-items {
    position: relative;
}

.menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.menu-items button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
}

.menu-items a,
.menu-items button {
    text-align: left;
}

.menu-sub-items a,
.menu-sub-items button {
    text-align: right !important;
    padding: 0.9rem 1rem;
}

.menu-sub-items {
    margin-left: 0px !important;
}

.menu-items a:hover,
.menu-items button:hover {
    background-color: #e3e3e3;
}

.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.dropdown {
    position: absolute !important;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 8rem;
    padding-top: 0.5rem;
    list-style: none;
    background-color: #fff;
    border-radius: 0.3rem;
    display: none !important;
}

.dropdown.show {
    display: block !important;
}

.dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}

.select-sim {
    vertical-align: middle;
    position: relative;
    border: 1px solid #4D4D4C;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 13px 65px 0px 15px;
    margin-left: 20px;
    height: 42px;
    font-family: 'Amble-bold';
    color: #4D4D4C;
    font-size: 14px;
}

.select-sim::after {
    content: "";
    background-image: url(../../static/media/arrowselect.580109da.webp);
    font-size: 0.5em;
    font-family: arial;
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    right: 10px;
    transform: translate(90%, -30%);
}

.container-header-rs .close-modal {
    display: none;
}

.select-sim .options .option input {
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    position: absolute;
}

.select-sim .options .option input:checked+label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50px;
}

.select-sim .options .option input:checked+label span {
    margin-left: 10px;
}

.Quote-btn {
    position: relative;
}

.header-rs {
    display: none;
}

@media only screen and (max-width: 1440px) {
    .Container-header_pm .Nav-pm ul li {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media only screen and (max-width: 1280px) {
    .movil-header {
        height: 70px;
    }

    .container-header,
    .container-header-height {
        display: none;
    }

    .header-rs {
        display: block;
        position: fixed;
        z-index: 9999999;
        width: 100%;
        background: #FFFFFF;
    }

    .container-header-rs {
        position: relative;
        max-width: 1840px;
        margin: 0 auto;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(15, 1fr);
        z-index: 999999;
    }

    .container-header-rs .Logo-pm {
        display: flex;
        padding: 10px;
        justify-content: center;
        grid-column: 1/3;
    }

    .options-pm-rs {
        grid-column: 13/16;
        display: flex;
        justify-self: flex-end;
        align-items: center;
        padding: 10px;
    }

    .bars {
        margin-left: 20px;
    }

    .container-header-rs .Nav-pm {
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 45px;
        grid-column: 1/16;
        padding-left: 0;
        padding-right: 0;
    }

    .movile-active .container-header-rs .Nav-pm ul {
        list-style: none;
        width: 100%;
        background: #fff;
        transition: 0.5s;
        transform: translateX(0%) !important;
        position: absolute;
        left: 0;
        top: -15px;
        display: table;
        padding-top: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }

    .container-header-rs .Nav-pm ul {
        list-style: none;
        width: 100%;
        background: #fff;
        transition: 0.5s;
        transform: translateX(-100%) !important;
        position: absolute;
        left: 0;
        display: table;
        padding-top: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }

    .container-header-rs .Nav-pm li {
        width: 100%;
        border-top: 1px solid rgba(118, 118, 118, 0.2);
        padding: 15px 20px;
    }

    .container-header-rs .Nav-pm ul li a {
        font-family: 'Amble-bold';
        color: #4D4D4C;
        font-size: 14px;
    }

    .container-header-rs .Nav-pm ul li a.active-mv {
        color: #009FE3 !important;
        border-bottom: none !important;
        font-size: 14px;
        font-family: 'Amble-bold';
    }

    .container-header-rs .Nav-pm ul li a.active-menu-item-mv {
        color: #009FE3 !important;
        border-bottom: none !important;
        font-size: 14px;
        font-family: 'Amble-bold';
        padding-left: 15px;
    }

    .container-header-rs .Nav-pm ul li a.menu-item-mv {
        color: #4D4D4C !important;
        border-bottom: none !important;
        font-size: 14px;
        font-family: 'Amble-bold';
        padding-left: 15px;
    }

    .movile-active .container-header-rs .Ecommerce-btn button {
        display: block !important;
        margin-top: 6px;
        margin-bottom: 2px;
    }

    .movile-active .container-header-rs .Logo-pm svg {
        display: none;
    }

    .movile-active .container-header-rs .close-modal {
        display: block;
    }

    .movile-active .container-header-rs .bars,
    .movile-active .container-header-rs .Quote-btn {
        display: none;
    }
}

.Lng-es {
    z-index: 1;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.Lng-es button {
    margin-left: 10px;
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 0px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 26px;
    cursor: pointer;
    outline: none;
}

.Lng-en {
    z-index: 1;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.Lng-en button {
    margin-right: 10px;
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 0px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 26px;
    cursor: pointer;
    outline: none;
}

.Lng {
    z-index: 1;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.Lng p {
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 10px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 26px;
    outline: none;
}
.Slicks-pm {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    height: auto;
    background: #f9f9f9;
}

.Slicks-pm.Us {
    background: #fff;
}

.slick-slider {
    width: 100%;
    height: 100%;
}

.slick-list {
    z-index: 2;
    padding: 0 !important;
}

.Radius {
    position: absolute;
    width: 2200px;
    height: 1206.42px;
    left: -158px;
    top: -360.45px;
    /* background: rgba(119, 121, 114, 0.35); */
    background: rgba(152, 194, 29, 0.35);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}

.Radius.Us {
    position: absolute;
    width: 2200px;
    height: 1206.42px;
    left: -158px;
    top: -360.45px;
    z-index: 2;
    background: #CBDCE7 !important;
    border-radius: 140px !important;
    transform: rotate(-16.85deg);
}

.Radius.Us.Clients, .Radius.Us.News {
    background: #D6E3D2 !important;
}

.Rotate-slick.Us::before {
    background-color: rgba(0, 82, 134, 0.2);
}

.Rotate-slick::before {
    position: absolute;
    z-index: 1023;
    content: '';
    background-color: rgba(0, 82, 134, 0.2);
    left: 45px;
    top: 240px;
    transform: rotate(21.34deg);
    width: 100%;
    height: 110%;
}

.Rotate-slick {
    overflow: hidden;
    position: relative;
    width: 2200px;
    height: 1206.42px;
    top: -410px;
    z-index: 3000;
    left: -153px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Rotate-slick::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    /* background-color: rgba(144, 145, 135, 0.3); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 28px;
    top: 346px;
    background-image: url(../../static/media/bannerexpress.c655eeb5.webp);
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 101%;
}
.Rotate-slick.first::before {
    background-color: transparent !important;
}
.Rotate-slick.first::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    left: -28px;
    top: 426px;
    background-image: url(../../static/media/bannerbioform-v2.5d767006.jpg);
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 101%;
}

.Rotate-slick.first p {
    margin: 10px 0px 10px 0;
}

.Rotate-slick.second::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(8, 117, 167, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    left: 28px;
    top: 346px;
    background-image: url(../../static/media/bannerhome.10d22d9e.webp);
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 101%;
}

.Rotate-slick.Us::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: cover;
    left: -26px;
    top: 346px;
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 100%;
}

.Rotate-slick.Us::after {
    background-image: url(../../static/media/bannerus.54b81b68.webp);
}

.Rotate-slick.Us.Sb::after {
    background-image: url(../../static/media/bannersostenibility.04fca96b.webp);
}

.Rotate-slick.Us.Wu::after {
    background-image: url(../../static/media/workwithus.5139d59c.webp) !important;
}

.Rotate-slick.Us.Cl::after {
    background-image: url(../../static/media/clients.059ae322.webp) !important;
}

.Rotate-slick.Us.New::after {
    background-image: url(../../static/media/bannernotice.f27e542b.webp) !important;
}

.Rotate-slick.Us.Ctt::after {
    background-image: url(../../static/media/contacto.1747c2ad.webp) !important;
}

.Radius-us{
    position: absolute;
    width: 2200px;
    height: 1206.42px;
    left: -58px;
    top: -49.45px;
    background: rgba(0, 82, 134, 0.16);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}

.Slicks-us_pm {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    height: auto;
    background: #f9f9f9;
}

.Slicks-sb_pm , .Slicks-wwu_pm, .Slicks-news_pm, .Slicks-cnt_pm {
    position: relative;
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
    height: 75vh;
    background: #FFFFFF;
}

.Slicks-clts_pm {
    position: relative;
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
    height: 75vh;
    background: #FFFFFF;
}

.Radius-sb, .Radius-wwu, .Radius-cnt {
    position: absolute;
    width: 1767.76px;
    height: 55vh;
    left: -58px;
    top: -49.45px;
    background: rgba(0, 82, 134, 0.16);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}

.Radius-clts, .Radius-news {
    position: absolute;
    width: 1767.76px;
    height: 55vh;
    left: -58px;
    top: -49.45px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}


.Rotate-slick_us {
    overflow: hidden;
    position: relative;
    width: 2700px;
    height: 94vh;
    top: -600px;
    left: -102px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Rotate-slick_sb, .Rotate-slick_wwu, .Rotate-slick_clts, .Rotate-slick_news, .Rotate-slick_cnt {
    overflow: hidden;
    position: relative;
    width: 2700px;
    height: 94vh;
    top: -600px;
    left: -102px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Rotate-slick_us::before ,
.Rotate-slick_sb::before , 
.Rotate-slick_wwu::before, 
.Rotate-slick_clts::before, 
.Rotate-slick_news::before,
.Rotate-slick_cnt::before  {
    position: absolute;
    z-index: 1023;
    content: '';
    width: 1781.73px;
    height: 1315.57px;
    left: -318px;
    background-color: rgba(0, 82, 134, 0.3);
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.text-map_pm.tablet {
    display: none;
}

.Rotate-slick_us::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_wwu::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: cover;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}


.Rotate-slick_sb::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_clts::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_news::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(20, 20, 20, 0.2);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_cnt::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-prueba {
    overflow: hidden;
    position: relative;
    width: 1920px;
    height: 100vh;
    top: -330px;
    left: -152px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Elipse {
    position: absolute;
    z-index: 999999;
    top: 50px;
    left: 234px;
    transform: rotate(21.34deg);
    background-repeat: no-repeat;
}

.Elipse img {
    width: 378px;
    height: 408px;
}

.biof {
    position: absolute;
    z-index: 999999;
    top: 260px;
    left: 350px;
    transform: rotate(21.34deg);
    background-repeat: no-repeat;
}

.biof img {
    width: 133px;
    height: 110px;
}

.Slicks-pm ul.slick-dots {
    transform: rotate(90deg);
    right: 0px;
    top: 270px;
    z-index: 1000;
    width: 20%;
    height: 10%;
}

.slick-dots li button:before {
    opacity: 1 !important;
    color: transparent !important;
    padding: 1px !important;
    width: 8px !important; 
    height: 8px !important;
}


.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    border-radius: 50%;
    background: #fff;
}

.slick-dots li {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 12px !important; 
    height: 12px !important;
}

.info-slick {
    position: relative;
    left: 160px;
    top: 840px;
    z-index: 99999999;
    transform: rotate(21.34deg);
}

.info-slick.Us {
    position: relative;
    left: 360px;
    top: 700px;
    z-index: 999999;
    transform: rotate(21.34deg);
}

.Rotate-slick.Us.Main .info-slick.Us {
    left: 260px;
    top: 600px ;
}

.info-slick.Us p {
    width: 385px;
}

.info-slick_us,.info-slick_sb ,.info-slick_wwu, .info-slick_clts, .info-slick_news {
    position: relative;
    left: 50px;
    top: 860px;
    z-index: 999999;
    transform: rotate(21.34deg);
}

.info-slick h2,.info-slick_us h2, .info-slick_sb h2, .info-slick_wwu h2, .info-slick_clts h2, .info-slick_news h2  {
    color: #fff;
    font-size: 50px;
    width: 485px;
    font-family: 'Amble';
}

.info-slick p ,.info-slick_us p, .info-slick_sb p, .info-slick_wwu p, .info-slick_clts p , .info-slick_news p{
    color: #fff;
    font-size: 19px;
    margin-top: 20px;
    font-family: 'Amble-light';
    margin-bottom: 40px;
}

.info-slick .more-btn button{
    color: #fff;
    font-size: 15px;
    margin-top: 20px;
    background: #32711E;
    font-family: 'Amble-bold';
    border: none;
    border-radius: 30px;
    padding: 10px 45px;
    cursor: pointer;
    transition: 0.6s;
    outline: none;
}

.info-slick a {
    color: #fff;
    font-size: 15px;
    margin-top: 20px;
    text-decoration: none;
    background: #32711E;
    font-family: 'Amble-bold';
    border: none;
    border-radius: 30px;
    padding: 10px 45px;
    cursor: pointer;
    transition: 0.6s;
    outline: none;
}

.info-slick .more-btn button:hover{
    background: #409427 !important;
    transition: 0.6s;
}

.footer-slick {
    position: relative;
    top: -280px;
}

.footer-slick .tree-pm img {
    position: absolute;
    z-index: 1;
}

.content-slick-home {
    height: 125vh;
}

.content-slick-new {
    height: 128vh !important;
}

.info-map_pm.title {
    display: none
}

@media only screen and (max-height: 660px) {
    .Rotate-slick.Us {
        background-size: cover !important;
        width: 1560px !important;
        height: 130vh !important;
        top: -450px !important;
        left: -78px !important;
    }
    .Radius.Us {
        height: 66vh !important;
    }
    .Rotate-slick.Us::before {
        height: 151vh !important;
    }
    .content-slick-home {
        height: 170vh !important;
    }
    .content-slick-new {
        height: 94vh !important;
    }
}

@media only screen and (max-width: 1370px) and (max-height: 900px) {
    .content-slick-home {
        height: 145vh !important;
    }
}

@media only screen and (max-width: 1440px) and (max-height: 900px) {
    .Rotate-slick.Us {
        height: 104vh !important;
    }
    .Rotate-slick.Us.Sb::after {
        top: 515px !important;
        left: -120px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 615px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl::after {
        top: 595px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.New::after {
        top: 435px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 615px !important;
        left: -280px !important;
    }
}

@media only screen and (max-width: 1440px) and (max-height: 750px) {
    .content-slick-new {
        height: 80vh !important;
    }
    .Rotate-slick.Us {
        height: 111vh !important;
    }
    .Radius.Us {
        top: -70px !important;
    }
    .info-slick.Us {
        left: 50px !important;
    }
    .Rotate-slick.Us.Sb::after {
        top: 515px !important;
        left: -120px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 615px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl::after {
        top: 595px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.New::after {
        top: 435px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 615px !important;
        left: -280px !important;
    }
    .tree-new.contact img {
        top: 100px !important;
    }
}

@media only screen and (max-width: 1920px) and (max-height: 735px) {
    .content-slick-home.bg {
        height: 149vh !important;
    }
}
  
@media only screen and (max-width: 1440px) and (max-height: 900px) { 
    .Rotate-slick::after {
        background-size: contain !important;
    }
    .content-slick-home {
        height: 125vh;
    }
    .Rotate-slick.Us.Main .info-slick.Us {
        left: -20px !important;
        top: 880px !important;
    }
    .Rotate-slick.Us::after {
        background-size: contain;
    }
    .info-slick.Us {
        left: 250px;
        top: 700px;
    }
    .Rotate-slick::before {
        height: 110%;
    }
    .tree-new {
        top: -90px !important;
    }
    .Radius.Us {
        position: absolute;
        width: 1767.76px;
        height: 52vh;
        left: -58px;
        top: -60.45px;
        background: rgba(0, 82, 134, 0.16);
        border-radius: 191px;
        transform: rotate(-20.85deg);
    }
    .info-slick.Us {
        transform: rotate(26.34deg);
        top: 1000px !important;
    }
    .Rotate-slick.Us {
        width: 2700px;
        height: 111vh;
        top: -750px;
        left: -176px;
        border-radius: 120px;
        transform: rotate(-26.34deg);
    }
    .Rotate-slick.Us::after {
        background-color: rgba(0, 82, 134, 0.2);
        background-repeat: no-repeat;
        left: -260px;
        top: 700px;
        opacity: 1;
        transform: rotate(26.34deg);
        width: 1560px;
    }
    .Rotate-slick.Us::before {
        min-height: 142vh;
        left: -80px;
        width: 100%;
    }
    .info-slick.Us {
        top: 700px;
        left: 100px;
    }
}

@media screen and (max-width: 1440px) and (max-height: 660px) {
    .img-new_slick_us, .img-new_slick {
        left: 0px !important;
    }
    .content-slick-new {
        height: 98vh !important;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 110vh !important;
    }
    .Rotate-slick.Us::after {
        top: 350px;
        left: -125px;
    }
    .info-slick.Us {
        top: 700px !important;
    }
    .Known-pm {
        margin-top: 0px !important;
    }
}

@media screen and (max-width: 1370px) and (max-height: 660px)  {
    .Rotate-slick::after{
        background-size: unset !important;
    }
    .content-slick-home {
        height: 120vh;
    }
    .Rotate-slick.Us::after {
        background-size: contain !important;
        max-width: 1440px !important;
        min-height: 141vh !important;
    }
    .info-slick.Us {
        left: 250px;
        top: 700px;
    }
    .Rotate-slick::before {
        height: 110%;
    }
    .new-slick .slick-dots {
        left: 200px !important;
    }
    .tree-new {
        top: -90px !important;
    }
    .Radius.Us {
        position: absolute;
        width: 1767.76px;
        height: 60vh;
        left: -58px;
        top: -60.45px;
        background: rgba(0, 82, 134, 0.16);
        border-radius: 191px;
        transform: rotate(-20.85deg);
    }
    .info-slick.Us {
        transform: rotate(26.85deg);
        top: 650px !important;
    }
    .Rotate-slick.Us {
        height: 120vh;
        top: -750px;
        left: -86px !important;
        border-radius: 191px;
        transform: rotate(-26.34deg);
    }
    .Rotate-slick.Us::after {
        background-color: rgba(0, 82, 134, 0.2);
        background-repeat: no-repeat;
        left: -260px;
        top: 300px;
        opacity: 1;
        transform: rotate(26.34deg);
        width: 1560px;
        min-height: 100vh;
    }
    .Rotate-slick.Us::before {
        min-height: 142vh;
        left: -80px;
        width: 100%;
    }
    .info-slick.Us {
        top: 700px;
        left: 100px;
    }
}

@media only screen and (max-width: 1370px) {
    .img-new_slick_us, .img-new_slick {
        left: 120px !important;
    }
    .img-new_slick {
        left: 30px !important;
    }
    .new-slick.work.sbl.community.table .slick-dots {
        left: -395px !important;
    }
    .new-slick.plant .slick-dots {
        left: 185px !important;
    }
    .stb-slick.tablet .slick-dots {
        left: 435px !important;
    }
    .new-slick.work.activity.tablet {
        grid-column: 2/8 !important;
    }
    .new-slick.work.activity.tablet .slick-dots {
        left: 710px !important;
    }
    .new-slick.work.tablet .slick-dots {
        left: -400px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .Rotate-slick.Us {
        height: 110vh;
        top: -820px;
        left: -135px;
    }
    .Radius.Us {
        height: 70vh;
        left: -50px;
        top: -250px;
    }
    .Rotate-slick.Us::after {
        height: 140vh !important;
        top: 700px;
        left: -465px;
        background-size: unset !important;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 80vh !important;
    }
    .tree-new.contact img {
        top: 350px !important;
        right: 45px;
        width: 200px !important;
    }
    .new-slick.tablet .slick-dots {
        left: 170px !important;
    }
    .img-carbajal,.square-carbajal {
        max-width: 572px;
        max-height: 340px;
    }
    .info-vision, .info-mision, .info-promise_value, .info-propose ,.square-mision, .square-vision, .square-promise_value, .square-propose {
        width: 230px !important;
    }
    .container-vision_mision_pm {
        flex-wrap: wrap;
    }
    .img-new_slick_us {
        height: 340px;
    }
    .img-new_slick_us,.container-news .square-slick , .tablet .img-new_slick,.card-about .img-about_us,.square.us{
        max-width: 500px;
        height: 340px;
    }
    .img-new_slick_us::after{
        height: 340px !important;
    }
    .img-about_us .img::after {
        height: 94% !important;
    }
    .tablet .container-news .square-slick {
        right: 20% !important;
    }
    .tablet .img-new_slick {
        left: 0px !important;
    }
    .new-slick.plant.tablet .img-new_slick {
        left: 120px !important;
    }
}

@media screen and (max-width: 1280px) and (max-height: 800px) {
    .content-slick-home {
        height: 140vh !important;
    }
    .info-slick.Us {
        left: 10px;
    }
    .Rotate-slick.Us {
        height: 110vh !important;
    }
    .Rotate-slick.Us.Main::after {
        top: 605px !important;
        left: -350px !important;
    }
    .Rotate-slick.Us.Main.tablet .info-slick {
        top: 890px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.Sb::after {
        top: 605px !important;
        left: -240px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 615px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl::after {
        top: 695px !important;
        left: -260px !important;
    }
    .Rotate-slick.Us.New::after {
        top: 635px !important;
        left: -380px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 615px !important;
        left: -280px !important;
    }
}


@media only screen and (max-width: 1024px) {
    .container-header-rs .Logo-pm {
        grid-column: 1/4;
        display: block;
    }
    .container-header-rs .Logo-pm a{
        margin-left: 2px;
    }
    .movile-active .container-header-rs .Ecommerce-btn button {
        position: relative;
        top: -15px;
        left: 10px;
    }
    .content-slick-home {
        height: 90vh;
    }
    .container-known .info-known ,.products-known {
        grid-column: 1/7 !important;
        display: flex;
        justify-content: center;
    }
    .products-known {
        margin-top: 60px;
    }
    .container-products_pm .top {
        top: 0px !important;
    }
    .container-products_pm {
        grid-gap: 50px !important;
        margin-left: 140px;
    }
    .container-products_pm img {
        width: 300px !important;
        height: 200px;
    }
    .material-pm a, .line-products_pm a{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container-title-new h6 {
        grid-column: 2/7 !important;
        margin-left: 35px;
    }
    .container-products_business {
        grid-column: 1/6 !important;
        margin-left: 100px !important;
        justify-content: flex-start !important;
    }
    .container-products_business > div {
        margin-left: 75px !important;
    }
    .info-distributor_hm div ,.info-distributor_hm p{
        width: 90% !important;
        text-align: center;
        margin: 0 auto;
    }
    .info-distributor_hm ,.img-distributor_hm{
        grid-column: 2/7 !important;
        display: flex;
        justify-content: flex-start;
    }
    .img-distributor_hm {
        margin-top: 50px;
    }
    .img-distributor {
        width: 600px !important;
        height: 400px !important;
    }
    .square-distributor {
        width: 600px !important;
        height: 400px !important;
        top: 35px !important;
        right: 40px !important;
    }
    .img-distributor img ,.img-video_known .img img{
        width: 100%;
        object-fit: cover;
        height: 400px !important;
    }
    .container-known_video,.info-known_our ,.title-client,.img-clients_pm {
        grid-column: 2/7 !important;
    }
    .info-known_our {
        grid-row: 1/2;
        left: 0px !important;
    }
    .News-pm .tree-new {
        right: 100px !important;
        top: -10px !important;
    }
    .News-pm {
        overflow: hidden;
    }
    .News-pm .tree-new.news img {
        width: 150px;
        transform: rotate(48.73deg);
    }
    .News-pm .slick-slider {
        position: relative !important;
        z-index: 1000 !important;
    }
    .container-known_video .play-known {
        margin-top: 40px !important;
    }
    .container-known_video {
        padding-top: 0px !important;
    }
    .img-clients_pm {
        margin-top: 40px !important;
    }
    .container-known_video .play-known .square, .square ,.img-video_known .img {
        width: 600px !important;
        height: 400px !important;
    }
    .play-known .square {
        left: 20px !important;
    }
    .info-known_our {
        margin-top: 100px !important;
        text-align: center;
    }
    .container-known_video {
        grid-template-columns: repeat(7,1fr) !important;
    }
    .info-certifications ,.certifications-companies,.info-new_slick, .post-new_slick,.play-known {
        grid-column: 2/7 !important;
        left: 0 !important;
    }
    .info-new_slick {
        margin-top: 30px !important;
    }
    .info-new_slick h6,.info-new_slick p {
        width: 100% !important;
    }
    .container-certifications {
        margin-top: 0px !important;
    }
    .info-certifications div, .info-certifications span, .info-certifications p {
        width: 99% !important;
    }
    .certifications-companies > div {
        margin-top: 20px !important;
    }
    .companies_us {
        margin-bottom: 60px !important;
    }
    .img-company-abe {
        top: -10px;
    }
    .img-company-gptw21 {
        top: -10px;
    }
    .tree-new {
        top: -180px !important;
    }
    .new-slick.tablet .slick-dots {
        left: 0px !important;
        margin: 0px auto;
    }
    .post-new_slick {
        padding-bottom: 260px !important;
    }
    .info-distributor_hm h3 {
        width: 90% !important;
        margin: 0 auto;
    }
    .Rotate-slick.Us {
        height: 94vh;
    }
    .Radius.Us {
        height: 47vh;
        left: -45px;
        top: -350px;
        width: 2440px;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 55vh !important;
    }
    .Rotate-slick::before {
        top: 0 !important;
    }
    .Rotate-slick.Us {
        max-width: 1400px;
    }
    .info-carbajal.about {
        grid-column: 2/7 !important;
        justify-content: flex-start;
    }
    .info-carbajal.about div {
        width: 100%;
    }
    .img-carbajal.about {
        grid-column: 2/8;
        margin-top: 10px;
    }
    .img-map_pm {
        grid-column: 2/10 !important;
    }
    .info-map_pm {
        grid-column: 2/10 !important;
        justify-content: center !important;
        display: flex !important;
        right: 0 !important;
        margin-top: 40px;
        width: 100% !important;
        margin-bottom: 40px;
    }
    .info-map_pm.title {
        grid-column: 2/7 !important;
        justify-content: flex-start !important;
        margin-bottom: 0px !important;
    }
    .info-map_pm .info-map-flex{
        display: flex;
        width: 100% !important;
        justify-content: center;
    }
    .text-map_pm.tablet {
        display: block;
        padding-bottom: 50px !important;
    }
    .map-footer-none {
        display: none;
    }
    .pm-inf_sk {
        margin-right: 0px !important;
    }
    .skills-gc {
        justify-content: space-between !important;
    }
    .pm-inf_sk svg {
        width: 90px !important;
        height: 98px !important;
    }
    .container-about_us {
        padding-top: 40px !important;
    }
    .container-about_us p {
        width: 100% !important;
    }
    .info-about {
        grid-column: 2/7 !important;
    }
    .card-about {
        margin-top: 100px;
        grid-column: 2/7 !important;
        display: flex !important;
        justify-content: center !important;
    }
    .card-about .square.us {
        left: 0px !important;
    }
    .img-map_pm.information {
        justify-content: center !important;
    }
    .footer-dc {
        right: 0 !important;
    }
    .post-new_slick_us {
        grid-column: 2/8 !important;
        margin-top: 40px !important;
        margin-bottom: 40px;
    }
    .info-footer_news_us {
        grid-column: 2/8 !important;
    }
    .tablet .container-news .square-slick {
        right: 25% !important;
    }
    .new-slick.plant.tablet .square-slick {
        right: 35% !important;
    }
    .makeStyles-paper-1 {
        max-width: 860px !important;
    }
    .products-default {
        grid-template-columns: repeat(2,1fr) !important;
    }
    .img-footer_news_us {
        grid-column: 1/8 !important;
        margin-top: 50px;
        margin-bottom: 50px;
        justify-content: center !important;
    }
    .container-news {
        margin-top: 10px !important;
    }
    .square-us {
        display: none;
    }
    .square-new {
        position: relative !important;
        background: #D1EBFC !important;
    }
    .container-footer_news_us {
        padding-top: 0px !important;
    }
    .info-footer_news_us {
        grid-column: 2/7 !important;
        grid-row: 1/2;
    }
    .info-footer_news_us div ,.info-footer_news_us p{
        width: 100% !important;
    }
    .info-container {
        grid-column: 2/7 !important;
    }
    .info-container p {
        font-size: 18px !important;
    }
    .post-img-card-rigth {
        padding-top: 40px;
        padding-bottom: 0px;
        grid-column: 1/8 !important;
        display: flex !important;
        margin-top: 0px !important;
        margin-bottom: 40px !important;
        justify-content: center !important;
    }
    .card-body a, .card-body button {
        padding: 8px 15px !important;
        font-size: 9px !important;
    }
    .post-img-card-rigth {
        left: auto !important;
    }
    .img-card ,.square-img-card.right-up{
        max-width: 500px;
    }
    .etic .square-img-card.right-up {
        right: 30% !important;
        height: 340px !important;
        top: 70px!important;
        transform: rotate(-9.05deg) !important;
    }
    .img-card img {
        width: 100% !important;
    }
    .etic-pro {
        padding-top: 40px !important;
    }
    .container-products_business {
        width: 100% !important;
    }
    .container-news {
        background: #D1EBFC !important;
    }
    .tablet .container-news .square-slick {
        right: 20% !important;
        transform: rotate(-9.05deg) !important;
    }
    .post-sl-sbl {
        grid-column: 1/8 !important;
        padding-bottom: 80px;
    }
    .info-sl-sbl {
        grid-row: 1/2 !important;
        grid-column: 1/8 !important;
        width: 100% !important;
        margin-left: 45px;
        text-align: left !important;
    }
    .info-sbl {
        position: relative !important;
        right: 0 !important;
    }
    .stb-slick.tablet .slick-dots {
        left: 0 !important;
    }
    .square-stb {
        position: relative !important;
        background: #E6EEE4;
        background-image: none !important;
        margin-top: 70px;
    }
    .container-grid.sbl {
        padding-top: 40px !important;
    }
    .container-grid.sbl .info-pm {
        grid-column: 1/8 !important;
        justify-content: start;
        margin-left: 80px;
    }
    .container-grid.sbl .info-pm p {
        width: 90% !important;
        font-size: 12px !important;
    }
    .container-grid.sbl .play-known {
        padding-top: 100px;
    }
    .container-grid.sbl .play-button_pm {
        right: 20px !important;
    }
    .container-grid.pet .info-pet-sbl {
        grid-column: 1/8 !important;
        grid-row: 1/2 !important;
        text-align: left !important;
        max-width: 980px !important;
        display: block;
        margin-left: 170px;
    }
    .container-grid.pet {
        padding-top: 10px !important;
    }
    .post-pet-sbl {
        display: none;
    }
    .container-grid.tonel {
        margin-top: 0px !important;
    }
    .container-grid.tonel .info-container , .container-grid.first .info-container, .container-grid.community .info-container{
        grid-column: 1/7 !important;
        margin-left: 80px;
    }
    .container-grid.tonel .info-container p ,.container-grid.first .info-container p ,.container-grid.community .info-container p{
        font-size: 12px !important;
    }
    .container-grid.tonel .img-container ,.new-slick.work {
        grid-column: 1/8 !important;
    }
    .container-grid.first .square-img-card {
        max-width: 500px;
        height: 350px;
        top: 60px;
        right: 29% !important;
    }
    .new-slick.work {
        margin-left: 30px !important;
        margin-top: 60px;
    }
    .container-grid.videos .post-pet-sbl.activity , .container-grid.videos .new-slick.work.activity.tablet {
        display: block;
        width: 100% !important;
        grid-column: 1/8 !important;
        padding-top: 0px !important;
        padding-bottom: 180px !important;
    }
    .container-grid.videos .slick-initialized .slick-slide > div {
        width: 100% !important;
    }
    .container-grid.videos .new-slick.work.activity {
        right: 0px !important;
    }
    .container-grid.videos .new-slick.work.activity.tablet .slick-dots {
        left: 0px !important;
    }
    .container-grid.videos .new-slick.work.activity.tablet {
        padding-bottom: 10px !important;
    }
    .new-slick.work.activity .slick-slide > div > div {
        display: block !important;
    }
    .new-slick.work.activity .info-post-activity {
        left: 0 !important;
    }
    .container-grid.videos .square-pet-sbl.activity {
        left: 50px !important;
        top: 30px !important;
    }
    .container-grid.videos {
        background: #32711E;
    }
    .container-grid.videos .info-post-activity {
        grid-column: 1/8 !important;
    }
    .container-grid .info-pm {
        grid-column: 1/7 !important;
        margin-left: 100px;
    }
    .container-grid .play-known {
        grid-column: 1/8 !important;
        padding-top: 100px;
        justify-content: center !important;
    }
    .container-grid .play-known .square {
        right: 10px !important;
    }
    .container-grid .info-pm div{
        width: 100%;
    }
    .container-grid.why p {
        width: 100%;
    }
    .container-grid.why .square {
        left: 170px !important;
    }
    .container-grid.why .play-button_pm {
        right: 150px !important;
    }
    .container-grid.information {
        background: #fff;
    }
    .certifications-companies.info {
        grid-row: 1/2;
    }
    .certifications-companies.info p{
        width: 100% !important;
    }
    .container-grid.information .play-known .square {
        left: 160px !important;
    }
    .square-new.workwithme {
        display: none;
    }
    .container .container-grid.work {
        padding-top: 0px !important;
        background: #D1EBFC;
    }
    .container .container-grid.work .info-new_slick {
        grid-column: 1/8 !important;
        justify-content: flex-start !important;
        margin-left: 85px;
    }
    .container .new-slick.work.tablet .slick-dots {
        left: 0px !important;
        bottom: 30px !important;
    }
    .container-grid.elipse {
        background: #005286;
    }
    .container-grid.elipse .elipse-work {
        display: none;
    }
    .container-grid.elipse .info-pm_work {
        grid-row: 1/2;
        grid-column: 1/8 !important;
        text-align: center;
        margin-left: 0 !important;
    }
    .container-grid.elipse .info-pm_work h6 ,.container-grid.elipse .info-pm_work p , .accordion{
        max-width: 90% !important;
    }
    .container-grid.elipse .play-known .square {
        left: 160px !important;
    }
    .container-grid.elipse .info-container {
        grid-column: 1/7 !important;
        margin-left: 85px !important;
    }
    .container-grid.elipse .box-container {
        grid-column: 1/7 !important;
        width: 100% !important;
        margin-left: 85px !important;
    }
    .container-grid.elipse .box-container .container-accordion {
        max-width: 90% !important;
    }
    .container.workwithme {
        padding-bottom: 0px !important;
    }
    .container-grid.clients-page .info-clients {
        grid-column: 1/7;
        margin-left: 85px;
    }
    .container-grid.clients-page .info-clients div ,.container-grid.clients-page .info-clients p{
        width: 100% !important;
    }
    .container-grid.clients-page .prs-clients {
        display: none;
    }
    .container-grid.clients-page .info-client-footer {
        margin-left: 85px !important;
    }
}

@media only screen and (max-width: 768px) {
    .Rotate-slick.first::after {
        background-image: url(../../static/media/bioformmovil.4de90ccd.png) !important;
    }
    .Rotate-slick.movil.express::after {
        background-image: url(../../static/media/expressmobile.bd1555af.png) !important;
    }
}
.Mision-vision_pm {
    margin: 0 auto;
    background: #f9f9f9;
}

.container-vision_mision_pm {
    max-width: 1440px;
    display: flex;
    padding-top: 50px;
    margin: 0 auto;
    padding-bottom: 80px;
}

.container-vision, .container-mision , .container-promise-value , .container-propose{
    position: relative;
    height: 257.08px;
    margin-left: 70px;
}

.info-vision, .info-mision, .info-promise_value, .info-propose {
    position: relative;
    z-index: 1024;
    width: 295px;
    height: 254px;
    background: #FFFFFF;
    box-shadow: 0px 3.62686px 18.1343px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
    display: flex;
    justify-content: center;
}

.vision h6, .mision h6, .promise-value h6 , .propose h6 {
    color: #005286;
    font-size: 16px;
    font-family: 'Amble';
    position: relative;
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
}

.vision h6::after, .mision h6::after, .promise-value h6::after , .propose h6::after {
    content: '';
    position: absolute;
    top: 30px;
    left: 20%;
    border: 1.72014px solid #005286;
    background: #005286;
    width: 124px;
    height: 1px;
}

.info-mision p , .info-vision p, .info-promise_value p, .info-propose p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    width: 216px;
    height: 51px;
    color: #4D4D4C;
    margin-top: 60px;
    text-align: center;
}

.info-promise_value p {
    width: 272px;
    height: 68px;
}

.info-mision strong {
    font-family: 'Amble-bold';
}

.square-mision, .square-vision , .square-promise_value, .square-propose {
    position: absolute;
    z-index: 1;
    width: 295px;
    height: 248.26px;
    background: #D1EBFC;
    border-radius: 30px;
    transform: rotate(-3.38deg);
}

.square-mision ,.square-vision , .square-promise_value , .square-propose {
    left: -20px;
    top: 5px;
}

@media only screen and (max-width: 1440px) {
    .info-about {
        grid-column: 1/4;
        justify-content: center;
    }
    .info-carbajal {
        grid-column: 1/4 !important;
        justify-content: center;
    }
    .mision, .propose, .vision {
        margin-bottom: 30px !important;
    }
    .info-vision, .info-mision, .info-promise_value, .info-propose {
        width: 257px;
    }
    .container-vision, .container-mision , .container-promise-value , .container-propose{
        margin-left: 80px;
    }
    .square-mision, .square-vision , .square-promise_value, .square-propose {
        width: 257px;
    }
}

.News-pm {
    position: relative;
    margin: 0 auto;
    height: auto;
    background: #f9f9f9;
}

.News-pm_us {
    position: relative;
    height: auto;
    background: #FFFFFF;
    overflow: hidden;
}

.square-new {
    position: absolute;
    overflow: hidden;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(../../static/media/squarenews.a4817fb2.webp);
    background-repeat: no-repeat;
}

.square-stb {
    position: absolute;
    overflow: hidden;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(../../static/media/squarestb.2cdad2f1.webp);
    background-repeat: no-repeat;
}

.square-us {
    position: relative;
    max-width: 1708.8px;
    height: 1419.8px;
    top: 210px;
    right: 0px;
    background: rgba(0, 82, 134, 0.2);
    border-radius: 191px;
    transform: rotate(-21.12deg);
}

.square-sl-sbl {
    position: absolute;
    top: 40px;
    width: 578.67px;
    height: 393.04px;
    right: 0px;
    z-index: 0;
    background: rgba(141, 183, 46, 0.15);
    border-radius: 50px;
    transform: rotate(172.1deg);
}

.square-img-card {
    position: absolute;
    top: 0px;
    width: 667.49px;
    height: 453.04px;
    right: -115px;
    z-index: 0;
    background: rgba(141, 183, 46, 0.15);
    border-radius: 50px;
    transform: rotate(172.1deg);
}

.square-img-card.right-up {
    width: 578.45px;
    height: 393.04px;
    right: 10px;
    background: rgba(255, 255, 255, 0.14);
    transform: rotate(9.05deg) !important;
}

.square-pet-sbl {
    position: absolute;
    top: 40px;
    width: 578.67px;
    height: 393.04px;
    left: 120px;
    z-index: 0;
    background: rgba(141, 183, 46, 0.21);
    border-radius: 50px;
    transform: rotate(8.73deg);
}

.square-pet-sbl.activity {
    position: absolute;
    top: 140px !important;
    width: 437.67px;
    height: 471.04px;
    left: 100px !important;
    z-index: 0;
    background: #5B8D4B;
    border-radius: 50px;
    transform: rotate(8.73deg);
}

.elipse-work {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: -820px;
    height: 100%;
}

.elipse-work-videos {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    bottom: 0;
    background-position: bottom left;
    width: 1708.8px;
    height: 1489.8px;
    background-image: url(../../static/media/elipsevideo.bb379e77.webp);
    background-repeat: no-repeat;
}

.post-pet-sbl {
    grid-column: 1/4;
    position: relative;
}

.post-pet-sbl.activity {
    padding: 0px 0px 300px 10px;
}

.tree-new {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 10px;
}

.tree-new.news img {
    width: 240px;
    opacity: 0.5;
    position: relative;
    top: 150px;
    transform: rotate(138.73deg);
}

.tree-pm.banner img {
    width: 240px;
    opacity: 0.5;
    position: relative;
    top: 100px;
    transform: rotate(-128.73deg);
}

.tree-new.contact-info {
    position: absolute;
    left: 0px !important;
}

.tree-new.contact-info img {
    width: 240px;
    opacity: 0.5;
    top: -140px !important;
    position: relative;
    transform: rotate(148.73deg);
}

.new-slick.work {
    grid-column: 4/8;
    position: relative;
    z-index: 1001;
}

.new-slick.work.activity {
    grid-column: 2/7 !important;
    right: 100px;
}

.title-new {
    width: 204px;
    position: relative;
    z-index: 1;
}

.title-new h4 {
    color: #005286;
    font-size: 50px;
    font-family: 'Amble-bold';
}

.container-news {
    display: grid !important;
    position: relative;
    z-index: 1;
    align-items: center;
    grid-template-columns: repeat(7,1fr);
    margin-top: 200px;
    padding-bottom: 50px;
}

.container-detail_new .slick-dots {
    width: 70% !important;
}

.new-slick .slick-dots {
    bottom: 90px !important;
    background: transparent !important;
    text-align: left !important;
    z-index: 2500 !important;
    padding: 0 !important;
    width: 100px !important;
    left: 265px;
}

.new-slick .slick-dots li {
    border: none !important;
}

.new-slick .slick-dots ul li button::before {
    display: none;
}

.new-slick .slick-dots ul li button {
    font-size: 16px !important;
    color: #005286;
    font-family: 'Amble';
}

.new-slick.sbl .slick-dots ul li button {
    color: #32711E;
}

.new-slick .slick-dots ul {
    display: flex;
}

.new-slick .slick-dots ul li.slick-active, .new-slick .slick-dots ul li {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.new-slick .slick-dots ul li.slick-active {
    background: #fff;
    border: 1px solid #FFF !important;
}

.new-slick.sbl .slick-dots ul li.slick-active {
    background: #fff;
    border: 1px solid #FFF !important;
}

.new-slick .slick-dots ul li {
    background: transparent;
    border: 1px solid #005286 !important;
}

.new-slick.sbl .slick-dots ul li {
    background: transparent;
    border: 1px solid #32711E !important;
}

.new-slick .slick-arrow {
    display: none !important;
}

.new-slick.slick-slider {
    height: auto !important;
}

.slick-slide div {
    border: none !important;
    outline: none !important;
}

.info-new_slick {
    grid-column: 2/4;
}

.info-new_slick h4{
    color: #005286;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 20px;
    width: 262px;
}

.info-new_slick h6{
    color: #009FE3;
    font-size: 28px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 20px;
    width: 380px;
}

.info-new_slick p{
    width: 350px;
    margin-top: 30px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4D;
    margin-bottom: 10px;
}

.info-new_slick a {
    font-size: 14px;
    color: #4D4D4D;
    font-family: 'Amble-bold';
    text-decoration: underline;
}

.post-new_slick {
    position: relative;
    top: 70px;
    grid-column: 4/8;
    padding-bottom: 200px;
    display: flex;
    justify-content: center;
}


.post-new_slick_us {
    position: relative;
    grid-column: 4/8;
    padding-bottom: 120px;
    display: flex;
    justify-content: flex-start;
}

.square-slick {
    position: absolute;
    top: 40px;
    width: 578.45px;
    height: 393.04px;
    z-index: 0;
    background: rgba(0, 82, 134, 0.2);
    border-radius: 50px;
    transform: rotate(9.05deg);
}

.img-new_slick {
    position: relative;
    top: 40px;
    left: 40px;
    width: 579px;
    height: 385px;
}

.img-new_slick.activity {
    position: relative;
    top: 40px;
    left: 40px;
    width: 437px;
    height: 471px;
}

.img-card {
    position: relative;
    width: 814.15px;
}

.img-card::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.img-card img {
    border-radius: 50px;
    object-fit: cover;
    height: 100%;
}

.img-work_slick {
    position: relative;
    top: 40px;
    left: 40px;
    width: 602px;
    height: 384px;
}

.img-new_slick_us {
    width: 560px;
    height: 385px;
    position: relative;
    top: 40px;
    left: 40px;
}

.img-new_slick::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.img-new_slick_us::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.img-new_slick img {
    border-radius: 50px;
    width: 100%;
    object-position: 0px;
    object-fit: cover;
    height: 100%;
}

.img-new_slick_us img {
    border-radius: 50px;
    width: 100%;
}

.Footer-news {
    position: relative;
    width: 100%;
    max-width: 1440px;
    z-index: 2000;
    overflow: hidden;
    padding-bottom: 80px;
    left: 0;
}

.Footer-news_us {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.squeare-footer_new {
    position: absolute;
    z-index: -1;
    left: -110px;
    width: 720.08px;
    height: 471.87px;
    background: rgba(0, 159, 227, 0.27);
    border-radius: 50px;
    transform: rotate(8.73deg);
}

.img-footer_new {
    position: relative;
    width: 570px;
    height: 440px;
}

.img-footer_new::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.img-footer_new img {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.container-footer_news {
    display: grid;
    grid-template-columns: repeat(7,1fr);
    margin-top: 100px;
}

.container-footer_news_us,.container-title-new, .container-certifications {
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.container-certifications {
    margin-top: 80px;
}

.img-footer_news {
    grid-column: 1/4;
}

.info-footer_news {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
}


.img-footer_news div {
    display: flex;
    justify-content: center;
}

.info-footer_news h6 {
    width: 516px;
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 59px;
    color: #009FE3;
}

.info-footer_news p {
    width: 350px;
    margin-top: 30px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4D;
}

.companies {
    margin-top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.companies div, .companies-second div {
    margin-right: 30px;
}

.companies .img-company-sgs {
    position: relative;
    width: 68px;
    height: 67px;
    background-image: url(../../static/media/sgs.1442ce77.webp);
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies .img-company-sgsiso {
    position: relative;
    width: 68px;
    height: 67px;
    background-image: url(../../static/media/iso.b693d17a.webp);
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies .img-company-brc {
    position: relative;
    width: 67px;
    height: 58px;
    background-image: url(../../static/media/brcpackaging.1b8efa33.webp);
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies_us .img-company-abe, .companies-second_us .img-company-abe {
    position: relative;
    width: 140px;
    height: 140px;
    right: 12px;
    background-color: #F9F9F9;
    background-image: url(../../static/media/selloemprendedor.d03d7b6a.webp);
    background-size: cover;
    background-blend-mode: darken;
}

.companies-second_us .img-company-gptw21 {
    position: relative;
    width: 109px;
    height: 189px;
    right: 30px;
    background-image: url(../../static/media/gptw2021.ceeecc34.png);
    background-size: cover;
    position: relative;
}

.companies-second .img-company-gp {
    position: relative;
    width: 60px;
    height: 60px;
    background-image: url(../../static/media/greatperu.f2eb9b5c.webp);
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies-second .img-company-gp2 {
    position: relative;
    width: 60px;
    height: 60px;
    background-image: url(../../static/media/greatperu2.06d204fb.webp);
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies-second .img-company-gpl {
    position: relative;
    width: 183px;
    height: 57px;
    background-image: url(../../static/media/greatplaces.ef30b466.webp);
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies .img-company img {
    width: 100%;
    height: 100%;
}

.companies-second {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.companies-second .img-company img.large {
    width: 183px;
    height: 67px;
}

.img-footer_news_us {
    display: flex;
    grid-column: 2/5;
}

.info-footer_news_us {
    grid-column: 5/8;
    display: flex;
    justify-content: flex-start;
}

.container-footer_news_us {
    padding-top: 20px;
}

.info-footer_news_us h6 {
    color: #32711E;;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 50px;
    width: 280px;
}


.info-footer_news_us div.span {
    color: #8DB72E;
    font-size: 30px;
    width: 383px !important;
    font-family: 'Amble-bold';
    margin-top: 40px;
    line-height: 97.2%;
    margin-bottom: 20px;
}

.info-footer_news_us p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 504px;
    margin-top: 40px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4D4D4C;
    opacity: 0.8;
}

.Certifications {
    background: #f9f9f9;
    position: relative;
    z-index: 99999;
    height: auto;
    padding-bottom: 40px;
}

.info-certifications {
    grid-column: 2/5;
    display: flex;
}

.certifications-companies {
    grid-column: 5/8;
    display: flex;
}

.info-certifications div {
    width: 512px;
}

.info-certifications h6 {
    color: #32711E;;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 25px;
    width: 563px;
}

.info-certifications span {
    color: #8DB72E;
    font-size: 30px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 20px;
}

.info-certifications p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 431px;
    margin-top: 30px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #4D4D4C;
}

.companies_us {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.companies_us div, .companies-second_us div {
    margin-right: 70px;
}

.companies_us .img-company-sgs {
    position: relative;
    width: 103px;
    height: 101px;
    background-image: url(../../static/media/sgs.1442ce77.webp);
    background-size: cover;    
}

.companies_us .img-company-sgiso {
    position: relative;
    width: 103px;
    height: 101px;
    background-image: url(../../static/media/iso.b693d17a.webp);
    background-size: cover;    
}

.companies_us .img-company-brc {
    position: relative;
    width: 121px;
    height: 101px;
    background-image: url(../../static/media/brcpackaging.1b8efa33.webp);
    background-size: contain;
    position: relative;   
    background-repeat: no-repeat;         
}

.companies-second_us .img-company-gp {
    position: relative;
    width: 109px;
    margin-bottom: 30px;
    height: 109px;
    background-image: url(../../static/media/greatperu.f2eb9b5c.webp);
    background-size: cover;
    position: relative; 
}

.companies-second_us .img-company-gplast {
    position: relative;
    width: 109px;
    margin-bottom: 30px;
    height: 109px;
    background-image: url(../../static/media/greatplacelast.833ff9eb.webp);
    background-size: cover;
    position: relative; 
}

.companies-second_us .img-company-gpl {
    position: relative;
    width: 109px;
    height: 109px;
    margin-bottom: 30px;
    background-image: url(../../static/media/greatplaces.ef30b466.webp);
    background-size: cover;
    position: relative;
}

.companies-second_us .img-company-gpw {
    position: relative;
    width: 109px;
    height: 109px;
    margin-bottom: 30px;
    background-image: url(../../static/media/grateplacework.e19adc08.webp);
    background-size: cover;
    position: relative;
}

.companies_us .img-company img {
    width: 100%;
    height: 100%;
}

.companies-second_us {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap !important;
    align-items: center;
}

.companies-second_us div {
    margin-right: 30px;
}

.companies-second_us .img-company img.large {
    width: 183px;
    height: 67px;
}

.card-work {
    height: 537px;
    width: 437px;
    margin-left: 400px;
}

.container-grid.work .new-slick.work.tablet .card-work{
    height: 420px;
}

.container-grid.work .new-slick.work.tablet .header-work{
    height: 100% !important;
}

.container-grid.work .new-slick.work.tablet .card-body {
    padding: 35px 35px 35px 35px;
}

.card-work {
    background: #fff;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.card-work img {
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
}

.header-work {
    position: relative;
}

.header-work.sbl img {
    width: 100%;
    height: 460px;
}

.header-work::after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 26px;
}

.card-body {
    position: relative;
    top: -140px;
    z-index: 1;
    padding: 35px 35px 10px 35px;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.card-body h6 {
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 18px;
}

.card-body p {
    font-family: 'Amble-light';
    color: #4c4c4c;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-body .text {
    margin-bottom: 40px;
}

.card-body a , .card-body button{
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 20px;
    padding: 10px 30px;
    background: transparent;
    border: 1px solid #005286;
    border-radius: 26px;
    cursor: pointer;
    outline: none;
}

.new-slick.work .slick-dots {
    bottom: 205px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
    left: -520px !important;
}

.new-slick.work.activity .slick-dots {
    bottom: 25px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
}

.container-known_video .player-wrapper {
    position: absolute;
    width: 100%;
    right: 0%;
    height: 65vh;
    top: 3%;
}

.container-grid.sbl .player-wrapper {
    position: absolute;
    width: 80%;
    right: 10%;
    height: 74vh;
    top: 3%;
}

.new-slick.work.activity .slick-dots ul li {
    border: 1px solid #fff !important;
}

.new-slick.work.activity .slick-dots ul li.slick-active button {
    color: #33711D !important;
}

.new-slick.work.activity .slick-dots ul li button {
    color: #fff !important;
}

.new-slick.work.sbl .slick-dots {
    bottom: 130px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
    left: -580px;
    position: absolute !important;
}

.certifications-companies h6.companies-title, .certifications-companies h6.companies-title_second {
    font-family: 'Amble-bold';
    color: #4c4c4c;
    font-size: 25px;
}

.certifications-companies h6.companies-title {
    margin-top: 30px;
}

.certifications-companies h6.companies-title_second {
    margin-top: 20px;
    margin-bottom: 25px;
}

.container-known_business {
    display: grid;
    grid-template-columns: repeat(7,1fr);
    background: #f9f9f9;
    margin: 0 auto;
}

.container-title-new h6 {
    grid-column: 2/4;
    font-family: 'Amble-bold';
    color: #32711E;
    font-size: 50px;
    width: 403px;
}

.container-products_business {
    grid-column: 1/8;
    display: flex;
    justify-content: space-between;
    margin: 60px auto;
    width: 960px;
}

.container-products_business img{
    border-radius: 50px;
}

.us-values_pm {
    background: #fff;
    margin: 0 auto;
}

.title-values {
    grid-column: 2/4;
    margin-top: 40px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    color: #009FE3;
    width: 301px;
    font-family: 'Amble';
    font-size: 50px;
}

.img-values {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 70px;
    flex-wrap: wrap;
    text-align: center;
}

.img-values p {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    width: 112px;
    color: #4d4d4d;
}

.news-search {
    margin: 0 auto;
    background: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.news-search h2, .news-search .text {
    text-align: center;
    margin-top: 100px;
}

.news-search h2 {
    font-family: 'Amble-bold';
    color: #32711E;
    font-size: 50px;
}

.news-search  .text {
    width: 600px;
    margin: 20px auto;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4C;
}

.search-date {
    max-width: 768px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(9,1fr);
    margin: 0px auto;
    grid-gap: 20px;
}

.input-select {
    width: 100%;
    margin-top: 20px;
}

.s1 {
    grid-column: 1/4;
}

.s2 {
    grid-column: 4/7;
}

.s3 {
    grid-column: 7/10;
}

.css-1ej2tn0-control:hover, .css-1ej2tn0-control {
    border-color: none !important;
    box-shadow: none !important;
}

.container-gallery {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 80px;
}

.gallery-item { 
    position: relative;
    font-family: 'Amble-light';
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.gallery-item::after {
    position: absolute;
    content: '';
    background: linear-gradient(162.44deg, rgba(0, 82, 134, 0.5) 23.72%, rgba(0, 82, 134, 0) 95.92%);
    border-radius: 30px;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    object-fit: cover;
}

.gallery-info {
    position: relative;
    z-index: 100;
    padding: 20px;
}

.gallery-item:nth-child(1) , 
.gallery-item:nth-child(2) ,
.gallery-item:nth-child(5) , 
.gallery-item:nth-child(6) ,
.gallery-item:nth-child(7) , 
.gallery-item:nth-child(8) ,
.gallery-item:nth-child(11),
.gallery-item:nth-child(12)
{
    grid-column-start: span 1;
    height: 300px;
}

.gallery-item:nth-child(3), 
.gallery-item:nth-child(4), 
.gallery-item:nth-child(9),
.gallery-item:nth-child(10),
.gallery-item:nth-child(15),
.gallery-item:nth-child(16) 
{
    grid-column-start: span 2;
    height: 300px;
}

.gallery-item .gallery-info {
    position: absolute;
    top: 0;
    color: #fff;
}

.gallery-date {
    font-size: 12px;
}

.gallery-default {
    font-size: 14px;
}

.gallery-title {
    font-family: 'Amble-bold';
    font-size: 23px;
    margin-top: 10px;
}

.btn-new {
    margin-top: 20px;
}

.btn-new a {
    font-family: 'Amble-bold';
    color: #fff;
    background: transparent;
    margin-top: 20px;
    border: 1px solid #fff;
    padding: 12px 16px;
    border-radius: 30px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.container-view_journalists {
    max-width: 1200px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    display: grid;
    grid-template-columns: repeat(6,1fr);
    border-radius: 30px;
    top: -40px;
    position: relative;
}

.info-view_journalists {
    grid-column: 1/3;
    padding: 5px 0px 30px 20px;
}

.info-view_journalists h2 {
    font-family: 'Amble-bold';
    color: #005286;
    text-align: left;
    font-size: 25px;
    width: 217px;
}

.info-view_journalists p {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    color: #4d4d4d;
    margin-bottom: 30px;
}

.info-view_journalists a {
    background: rgba(118, 118, 118, 0.06);
    color: #005286;
    border: 1px solid #005286;
    font-family: 'Amble-bold';
    padding: 12px 25px;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    outline: none;
}

.img-view_journalists {
    grid-column: 3/7;
    position: relative;
}

.img-view_journalists::after {
    position: absolute;
    background: rgba(0, 82, 134, 0.2);
    border-radius: 0px 30px 30px 0px;
    z-index: 1000;
    content: '';
    width: 100%;
    object-fit: cover;
    top: 0px;
    height: 100%;
    left: 0;
}

.img-view_journalists img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.Detail-new_pm {
    position: relative;
    height: 50vh;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
}

.Detail-new_pm img {
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.Detail-new_pm.gallery-paper {
    background-image: url(../../static/media/galeriaperiodisticabanner.27846414.webp) !important;
}

.Detail-new_pm::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
}

.container-detail_new {
    margin: 0 auto;
    background: #fff;
    padding: 20px 0px 20px 0px;
}

.container-detail_new h2 {
    text-align: center;
    font-size: 50px;
    font-family: 'Amble-bold';
    color: #32711E;
    width: 892px;
    margin: 30px auto;
}

.container-detail_new .slick-slider {
    padding: 0px 0px 40px 0px;
}

.container-detail_new .slick-dots {
    width: 100% !important;
    bottom: 0px !important;
}

.container-detail_new .slick-dots li button:before,.container-detail_new .slick-dots li.slick-active button:before {
    background: #fff;
    border: 2px solid #005286;
    border-radius: 50%;
}

.container-detail_new .slick-dots li.slick-active button:before {
    background: #005286 !important;
}

.info-detail {
    max-width: 998px;
    margin: 0 auto;
    padding: 40px;
}

.info-detail span {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    color: #4d4d4d;
}

.info-detail .text {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    text-align: justify;
    color: #4d4d4d;
    line-height: 20px;
    padding: 20px 50px 0px 50px;
}

.info-detail .text div {
    margin-bottom: 10px;
}

.info-detail .text a  {
    color: #009FE3;
}

.btn-access a {
    border: 1px solid #fff;
    border-radius: 31px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Amble-light';
    padding: 10px 20px;
}

.newpaper-gallery {
    border-radius: 0px 0px 30px 30px;
    background: #fff;
}

.newpaper-header-gallery {
    position: relative;
    width: 100%;
    height: 420px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.newpaper-header-gallery::after {
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    top: 0;
}

.newpaper-header-gallery img {
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 100%;
    object-fit: cover;
}

.newpaper-body {
    padding: 20px 20px 30px 20px;
}

.newpaper-body span {
    font-family: 'Amble-light';
    color: #005286;
    font-size: 12px;
}

.newpaper-body p {
    font-family: 'Amble-bold';
    color: #005286;
    margin-bottom: 20px;
    font-size: 22px;
}

.newpaper-body > a > a {
    font-family: 'Amble-bold';
    border: 1px solid #005286;
    background: #fff;
    border-radius: 30px;
    color: #005286;
    text-decoration: none;
    padding: 10px 20px;
}

.tree-new.contact {
    position: absolute;
    right: -115px;
    z-index: 1;
}

.tree-new.contact img {
    width: 300px;
    opacity: 0.5;
    position: relative;
    top: 500px;
    transform: rotate(138.73deg);
}

.mrbox svg circle {
    fill: #005286 !important;
}

.new-slick.work.sbl img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 26px;
}

@media only screen and (min-width: 1440px) {
    .container-news {
        margin-top: 300px;
    }
}

@media only screen and (max-width: 1440px) {
    .container-known_video .player-wrapper {
        position: absolute;
        width: 80%;
        right: 10%;
        height: 80vh;
        top: 5%;
    }
    .container-grid.sbl .player-wrapper {
        position: absolute;
        width: 90%;
        right: 4.4%;
        height: 83vh;
        top: 6%;
    }    
    .new-slick .slick-dots {
        left: 194px !important;
    }
    .container-detail_new .slick-dots {
        width: 70% !important;
        margin: 0 auto !important;
        bottom: 0px !important;
    }
    .info-certifications {
        grid-column: 1/5;
        display: flex;
        justify-content: center;
    }
    .tree-new.contact {
        right: -115px;
    }
    .container-detail_new .slick-dots {
        width: 70% !important;
        right: 165px !important;
    }
    .tree-new.contact img {
        width: 240px;
        opacity: 0.5;
        position: relative;
        top: 240px;
        transform: rotate(138.73deg);
    }
    .container-news .square-slick {
        right: 15% !important;
    }
    .square-us {
        top: 180px;
        right: 40px;
    }
    .square-sl-sbl {
        left: 100px;
    }
    .new-slick.work .slick-dots {
        bottom: 205px !important;
        left: -420px !important;
    }
    .card-work {
        margin-left: 280px;
    }
    .info-pm_work {
        margin-left: 100px !important;
    }
    .elipse-work {
        bottom: -570px;
    }    
    .post-img-card-rigth.etic {
        left: 0 !important;
    }
    .square-img-card.right-up {
        right: -70px;
    }
    .title-values {
        grid-column: 1/4;
        margin-left: 100px;
        color: #009FE3;
        width: 301px;
    }
    .tree-new.contact-info img {
        width: 240px;
        opacity: 0.5;
        top: 0px !important;
        position: relative;
        transform: rotate(148.73deg);
    }
}

.new-slick.work.sbl.community .slick-dots {
    left: -555px !important;
}

@media only screen and (max-width: 1440px) {
    .new-slick.work.sbl.community .slick-dots {
        left: -420px !important;
    }
    .new-slick.work.activity {
        grid-column: 2/8 !important;
    }
}

@media only screen and  (max-width: 1366px) {
    .new-slick.work.sbl .slick-dots {
        left: -400px !important;
    }
    .new-slick.work.activity {
        grid-column: 2/7 !important;
    }
    .container-grid.information .square {
        left: 0px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .Map_pm {
        padding-top: 0 !important;
    }
    .container-grid_img {
        margin-top: 100px;
    }
}
.Informations {
    position: relative;
    z-index: 1024;
    max-width: 1440px;
    margin: 0 auto;
    height: auto;
    background: #Fff;
    overflow: hidden;
}

.square-information {
    position: absolute;
    width: 1708.8px;
    height: 1519.8px;
    left: -150px;
    top: 210px;
    background: #005286;
    border-radius: 191px;
    transform: rotate(-16.18deg);
}

.Map_pm {
    padding-top: 150px;
}

.container-informations {
    position: relative;
    display: grid;
    background: #fff;
    z-index: 1;
    align-items: center;
    grid-template-columns: repeat(7,1fr);
    grid-gap: 60px;
}

.img-carbajal, .img-info_carbajal {
    position: relative;
    z-index: 1024;
    display: flex;
    grid-column: 4/8;   
}


.img-info_carbajal {
    justify-content: center;
    top: 190px;
}

.img-footer_news.informations {
    position: relative;
    margin-top: 300px;
}

.img-info_carbajal img {
    position: relative;
    right: 15px;
}

.img-info_carbajal h6 {
    margin-top: 40px;
    color: #fff;
    font-family: 'Amble-bold';
    font-size: 30px;
    margin-bottom: 20px;
}

.img-info_carbajal p {
    width: 512px;
    font-family: 'Amble-light';
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    line-height: 22px;
}

.square-carbajal {
    position: absolute;
    top: 0px;
    width: 720px;
    height: 471px;
    z-index: 1020;
    left: -20px;
    background: #D1EBFC;
    border-radius: 50px;
    transform: rotate(9.05deg);
}

.img-carbajal {
    width: 672px;
    height: 440px;
}

.img-carbajal .wc {
    width: 100%;
}

.img-carbajal img {
    position: relative;
    z-index: 1022;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

.img-carbajal::after {
    content: '';
    width: 100%;
    border-radius: 30px;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    z-index: 9999999;
    position: absolute;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.info-carbajal {
    display: flex;
    grid-column: 2/4;
}

.info-carbajal.about p {
    font-size: 14px;
    color: #4D4D4C;
}

.info-carbajal div {
    width: 351px;
}

.info-carbajal img {
    position: relative;
}

.info-carbajal h6 {
    font-family: 'Amble';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #fff;
    margin-top: 30px;
}

.info-carbajal p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.3%;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 30px;
}

.container-documents {
    display: grid;
    background: #fff;
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 80px;
    grid-template-columns: repeat(7, 1fr);
}

.info-documents {
    grid-column: 1/4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.info-documents .idms {
    width: 383px;
}

.info-documents h5 {
    font-family: 'Amble';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    color: #005286;
}

.info-documents p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.3%;
    color: #4D4D4C;
    margin-top: 40px;
}

.documents {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
}

.document {
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 31.5px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 431px;
}

.document button{
    background: #009FE3;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.document p {
    margin-left: 20px;
    font-family: 'Amble-light';
    text-decoration: underline;
    color: #005286;
    font-size: 14px;
}

.img-map_pm {
    grid-column: 2/6;
    display: flex;
    justify-content: center;
}

.img-map_pm img {
    max-width: 100%;
    object-fit: cover;
}

.info-map_pm {
    grid-column: 8/11;
    position: relative;
    right: 150px;
    padding-top: 20px;
}

.info-map_pm span {
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 55px;
    color: #005286;
}

.info-map_pm h6 {
    font-family: 'Amble-bold';
    font-size: 50px;
    width: 500px;
    line-height: 35px;
    color: #005286;
}

.container-grid_img {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.text-map_pm {
    text-align: center;
    color: #005286;
    font-family: 'Amble-light';
    font-size: 18px;
    margin-top: 20px;
}

.text-map_pm .bold {
    font-size: 31px;
    font-family: 'Amble-bold';
}

.footer-dc {
    position: relative;
    right: 40px;
}

.skills-gc {
    margin-top: 40px;
    display: flex;
    align-items: center;
}

.pm-inf_sk {
    text-align: center;
    margin-right: 60px;
}

.pm-inf_sk svg {
    margin-bottom: 10px;
}

.pm-inf_sk strong {
    color: #005286;
    font-family: 'Amble-bold';
    margin-top: 10px;
    font-size: 12px;
}

.pm-inf_sk p {
    color: #005286;
    font-family: 'Amble-light';
    font-size: 12px;
    width: 78px;
}

.footer-dc strong {
    font-family: 'Amble-bold';
}

.footer-dc {
    padding-top: 20px !important;
}

.more-btn button {
    font-family: 'Amble-light';
    font-size: 14px;
    color: #fff;
    padding: 8px 40px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    margin-top: 20px;
    outline: none;
    cursor: pointer;
}

.img-map_pm.information {
    justify-content: flex-start;
}

@media only screen and (max-width: 1440px) {
    .img-values {
        max-width: 998px;
    }
    .certifications-companies.info {
        justify-content: flex-end !important;
    }
}
.Footer-pm {
    position: relative;
    margin: 0 auto;
    background: #ECECEC;
}

.accordion-footer {
    width: 100% !important;
    grid-column: 1/10;
}

.collapse-footer .content {
    max-height: 0em;
    transition: 0.3s linear 0s;
    overflow: hidden;
  }

  .collapse-footer:target .content {
    max-height: 15em;
    margin-bottom: 0px;
  }

.sp-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sp-title svg {
    margin-right: 20px;
}

.container-footer {
    display: grid;
    grid-template-columns: repeat(9,1fr);
    align-items: flex-start;
    padding: 50px 100px 20px 100px;
    margin-bottom: 40px;
}

.Footer-pm.movil {
    display: none;
}

.logo {
    display: flex;
    grid-column: 1/3;
}

.claims {
    display: flex;
    grid-column: 3/4;
}

.declaration {
    display: flex;
    justify-content: flex-end;
    grid-column: 4/6;
}

.container-footer .information {
    display: flex;
    justify-content: flex-end;
    grid-column: 6/8;
}

.accordion-panel{
    padding: 1em;
    background:#444;
    color:#f4f4f4;
    display: none;
  }

.contact {
    display: flex;
    justify-content: flex-end;
    grid-column: 8/10;
}

.declaration .title {
    width: 168px;
    position: relative;
}

.information .title {
    width: 180px;
}

.contact .title a ,.declaration .title a , .information .title a {
    font-family: 'Raleway';
    font-size: 12px;
    color: #3d3d3d;
    text-decoration: none;
    font-weight: 700;
}

.contact .info-contact ,.declaration .info-contact a, .information .info-contact a{
    font-family: 'Raleway';
    font-weight: 300;
    font-size: 12px;
    color: #767676;
}

.contact .info-contact div ,.declaration .info-contact div, .information .info-contact div{
    margin-top: 23px;
}

.Footer-pm hr {
    width: 90%;
    margin: 0 auto;
    color: none;
    border: 1px solid rgba(118, 118, 118, 0.15);
}

.copy-socials {
    padding: 30px 100px 20px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copy-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.copy-right div.span {
    font-family: 'Amble-light';
    font-size: 14px;
    color: #767676;
}

.copy p {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Amble';
    font-size: 14px;
}

.socials a {
    margin-left: 10px;
    cursor: pointer;
}

.Footer-pm.movil .claims {
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
}
.Known-pm {
    position: relative;
    height: auto;
}

.container-known, .container-distributor {
    background: #F9F9F9;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(7,1fr);
    align-items: center;
    padding-bottom: 100px;
}

.container-known .info-known {
    grid-column: 2/4;
    display: flex;
}

.info-known h3 {
    width: 498px;
    font-family: 'Amble-bold';
    font-size: 50px;
    line-height: 50px;
    color: #009FE3;
}

.info-known p {
    width: 350px;
    margin-top: 30px;
    font-family: 'Amble-bold' !important;
    font-size: 20px;
    color: #4D4D4D;
}

.products-known {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
}

.container-products_pm {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
}

.container-products_pm img {
    border-radius: 50px;
}

.container-products_pm .top {
    position: relative;
    top: -100px;
}

.bioform-pm ,.line-products_pm, .material-pm, .use-app_pm {
    position: relative;
    display: flex;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.img-video_known .img::after, .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.bioform-pm::after, .line-products_pm::after, .material-pm::after,.use-app_pm::after {
    height: 98%;
}

.bioform-pm p,.line-products_pm p, .material-pm p, .use-app_pm p {
    position: absolute;
    color: #fff;
    z-index: 1;
    top: 60px;
    font-size: 29px;
    font-family: 'Amble';
    text-shadow: 0px 2.91257px 2.91257px rgba(0, 0, 0, 0.06);
}

.Known-video_pm {
    position: relative;
    z-index: 1;
    padding-bottom: 50px;
}

.know-lines {
    font-family: 'Amble';
    text-align: center;
    margin-top: 20px;
    font-size: 25px;
    color: #4D4D4D;
}

.container-known_video{
    padding-top: 100px;
    background: #f9f9f9;
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    position: relative;
    z-index: 1;
    grid-template-columns: repeat(8, 1fr);
}

.container-distributor {
    background: #005286;
    padding-top: 80px;
}

.container-known_video .play-known {
    position: relative;
    left: 40px;
    grid-column: 1/4;
    display: flex;
    justify-content: center;
}

.container-grid .info-pm {
    position: relative;
    grid-column: 1/5;
    display: flex;
    justify-content: center;
}

.info-pm h6 {
    width: 543px;
    font-family: 'Amble-bold';
    font-size: 50px;
    color: #32711E;
}

.info-pm p {
    width: 432px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4c4c4c;
    margin-top: 20px;
}

.container-grid .play-known {
    position: relative;
    left: 40px;
    grid-column: 5/7;
    display: flex;
    justify-content: flex-start;
}

.container-known_video .play-known .square, .square {
    position: absolute;
    width: 430px;
    left: 0;
    height: 471px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 50px;
    transform: rotate(-9.08deg);
}

.container-grid .play-known .square, .square , .square-carvajal {
    position: absolute;
    width: 430px;
    left: -70px;
    height: 471px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 50px;
    transform: rotate(-9.08deg);
}

.square {
    background: #D1EBFC;
    left: -60px;
}

.square-carvajal {
    background: rgba(255, 255, 255, 0.14);
}

.img-video_known .img {
    width: 430px;
    height: 471px;
    position: relative;
    z-index: 1;
    top: -30px;
}

.img-video_known .img img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.play-button_pm {
    position: absolute;
    z-index: 10;
    right: -10px;
    bottom: 90px;
    width: 142px;
    height: 142px;
}

.container-grid .play-button_pm {
    position: absolute;
    z-index: 10;
    right: 40px;
    bottom: 90px;
    width: 142px;
    height: 142px;
}

.play-button_pm.activity {
    left: 400px;
    bottom: 200px;
}

.play-known_carbajal .play-button_pm {
    position: absolute;
    z-index: 10;
    right: -130px;
    bottom: 90px;
    width: 142px;
    height: 142px;
}

.new-slick.work.activity .slick-slide > div > div {
    display: flex !important;
    align-items: flex-end;
    justify-content: space-between;
}

.info-post-activity {
    position: relative;
    top: -400px;
    left:-100px;
    width: 400px;
}

.info-post-activity p {
    z-index: 999999;
    color: #fff;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-bottom: 14px;
}

.info-post-activity span {
    color: #fff;
    font-size: 14px;
    font-family: 'Amble-light';
}

.play-button_pm button {
    background: #8DB72E;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    outline: none;
}
.play-button_pm button.blue {
    background: #009FE3;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    outline: none;
}

.info-known_our {
    position: relative;
    top: -40px;
    left: 40px;
    grid-column: 5/8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-known_our h4 {
    width: 497px;
    color: #8DB72E;
    font-size: 50px;
    font-family: 'Amble-bold';
}

.info-known_our p {
    width: 254px;
    margin-top: 30px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4D;
}

.info-distributor_hm {
    grid-column: 2/4;
    display: flex;
}

.info-distributor_hm div{
    position: relative;
    width: 400px;
}

.info-distributor_hm h3 {
    width: 400px;
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 104.2%;
    color: #FFFFFF;
}

.info-distributor_hm p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 345px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 35px;
    color: #FFFFFF;
}

.info-distributor_hm button {
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    background: #009FE3;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;
    padding: 12px 30px;
    margin-top: 35px;
    cursor: pointer;
    outline: none;
}

.img-distributor_hm {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
    position: relative;
}

.square-distributor {
    background: #33759E;
    border-radius: 50px;
    transform: rotate(-9.08deg);
    width: 411.23px;
    height: 443.65px;
    top: 20px;
    right: 60px;
    position: absolute;
}

.img-distributor {
    position: relative;
    width: 411.23px;
    height: 443.65px;
}

.img-distributor::after {
    background: rgba(0, 82, 134, 0.2);
    border-radius: 50px;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
}

.img-distributor_hm img {
    border-radius: 50px;
    position: relative;
}

/* Nosotros */

.About-us_pm {
    background: #f9f9f9;
    margin: 0 auto;
}

.container-about_us {
    padding-top: 100px;
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.info-about {
    grid-column: 2/5;
    margin-top: 0px;
}

.info-about h3 {
    width: 328px;
    font-family: Amble;
    font-style: normal;
    margin-top: 30px;
    font-family: 'Amble-bold';
    font-size: 30px;
    line-height: 104.2%;
    color: #009FE3;
}

.info-about p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 428px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4D4D4C;
    margin-top: 30px;
}

.card-about {
    position: relative;
    grid-column: 5/8;
    display: flex;
    justify-content: flex-start;
}

.container-square_us {
    position: relative;
    width: 634.39px;
    height: 471.87px;
}

.player-wrapper {
    position: absolute !important;
    width: 580px;
    height: 560px;
    z-index: 999999 !important;
    bottom: 250px;
}

.html5-video-player:not(.ytp-transparent), .html5-video-player.unstarted-mode, .html5-video-player.ad-showing, .html5-video-player.ended-mode, .html5-video-player.ytp-fullscreen {
    border-radius: 5px !important;
}

body.date-20201112 {
    border-radius: 5px !important;
}

.square.us {
    position: absolute;
    width: 564px;
    height: 420px;
    left: -90px;
    top: -50px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 50px;
    transform: rotate(-5.34deg);
}

.card-about .img-about_us{
    width: 530px;
    height: 420px;
    position: relative;
    z-index: 1;
    top: -45px;
    left: -55px;
}

.img-about_us .img img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.img-about_us .img::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.button-cat button, .button-cat a {
    display: block;
    width: 100%;
    margin-top: 20px;
    color: #32711E;
    border: 1px solid #32711E;
    border-radius: 30px;
    background: transparent;
    text-decoration: none;
    font-size: 13px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    font-family: 'Amble-bold';
    outline: none;
}

@media screen and (max-height: 750px) and (max-width: 1440px) {
    .Known-pm {
        margin-top: 200px;
    }
    .Known-pm .Known-pm {
        margin-top: 0px !important;
    }
    .container-news {
        margin-top: 200px;
    }
}

@media screen and (max-height: 1366px) and (max-width: 1024px) {
    .Rotate-slick::before {
        left: 92px;
    }
    .content-slick-new.new {
        height: 55vh !important;
    }
    .container-gallery ,.container-view_journalists{
        max-width: 980px;
    }
    .form-contact {
        grid-column: 2/9 !important;
        max-width: 980px;
    }
    .img-video_known .img img,.container-products_pm img, .img-video_known .img::after, .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
        border-radius: 20px !important;
    }
    .container-form_contact {
        padding-top: 0px !important;
    }
    .container-form_contact .prs-clients {
        display: none;
    }
    .tree-new.contact img {
        top: 480px !important;
    }
    .tree-new.contact-info img {
        width: 200px;
        top: 100px !important;
        left: -30px;
    }
    .footer-contact {
        padding: 40px 0px !important;
    }
    .container-grid .market-contact , .container-grid .call-contact {
        justify-content: flex-start !important;
    }
    .container-grid .call-contact {
        grid-column: 5/8;
    }
    .Distributor-pm {
        height: 20vh !important;
    }
    .ubications {
        grid-column: 1/8 !important;
        margin-top: 60px;
        margin-left: 160px !important;
    }
    .container-detail_product {
        padding-top: 40px !important;
    }
    .container-detail_product .img-detail_product {
        grid-column: 1/5 !important;
        padding-top: 40px !important;
        justify-content: center !important;
    }
    .container-detail_product .detail-info {
        grid-column: 1/5 !important;
        padding-top: 100px;
        margin-left: 85px;
    }
    .detail-info .title-detail_product h4 ,.list-detailt_product {
        max-width: 90% !important;
    }
    .products-default {
        grid-gap: 0px !important;
    }
    .form-wizard .wizard-fieldset.show {
        max-width: 868px !important;
    }
    .container-dates , .container-quotes-products{
        grid-column: 1/3 !important;
    }
    .form-wizard .form-wizard-steps li {
        width: 45% !important;
    }
}

@media screen and (max-height: 660px) and (max-width: 1370px) {
    .Known-pm {
        margin-top: 0px;
    }
    .Known-pm .Known-pm {
        margin-top: 0px !important;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 100vh !important;
    }
}

@media screen and (min-height: 1360px) and (max-width: 1024px) {
    .Known-pm {
        padding-top: 100px;
    }
    .Known-pm .Known-pm {
        padding-top: 0px;
    }
    .Rotate-slick::before {
        height: 130%;
    }
    .News-pm .tree-new {
        right: 0 !important;
    }
    .News-pm .tree-new.news img {
        right: 0 !important;
    }
}


@media only screen and (max-width: 1440px) {
    .container-grid .play-button_pm {
        right: -100px;
    }
    .img-distributor_hm {
        grid-column: 5/8;
    }
    .post-pet-sbl.activity {
        right: 0 !important;
    }
    .info-post-activity {
        z-index: 99999;
    }
    .new-slick.work.activity .slick-dots {
        left: 580px !important;
    }
    .player-wrapper {
        position: absolute !important;
        height: 500px;
        min-width: 14.3%;
        bottom: 90px;
        z-index: 999999 !important;
    }
}
.Claims {
    background: #fff;
}

.content-claims {
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    padding: 70px 70px 0px 70px;
}

.content-claims h2 {
    color: #145781;
    text-transform: uppercase;
    font-family: 'Amble-bold';
    font-size: 35px;
}

.content-claims p.subtitle-claims {
    font-family: 'Amble';
    margin: 0 auto;
    margin-top: 80px;
    max-width: 80%;
    font-size: 16px;
}

.divider {
    width: 20%;
    margin: 0 auto;
    margin-top: 50px;
}

.divider span {
    border-color: #ff8e32 !important;
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
}

.services-claims {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 60px;
}

.services-claims p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Amble';
    font-size: 25px;
}

.services-claims span {
    color: #808291;
    font-family: 'Amble-light';
}

.claims-form {
    background: #E8F0F8;
}

.claims-content__form {
    display: grid;
    max-width: 1280px;
    margin: 0 auto;
    grid-gap: 50px;
    align-items: center;
    padding: 80px 0px;
    grid-template-columns: repeat(2,1fr);
}

.img-content__form {
    max-width: 600px;
}

.img-content__form img {
    max-width: 100%;
}

.form-content {
    font-size: 30px;
    font-family: 'Amble';
}

.box{
    margin-top: 10px;
	padding: 0px;
	box-sizing: border-box;
	border-radius: 10px;
}

.box p {
    font-size: 18px;
    margin-top: 20px;
}

.box form {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(2,1fr);
}

.box .inputBox{
	position: relative;
}

.box .inputBox label {
    display: block;
    color: #808291;
    font-size: 13px;
    font-family: 'Amble';
}

.box .inputBox input, select {
    width: 100%;
    display: flex;
    height: 35px;
    padding-left: 1em;
    padding-right: 2em;
    margin-bottom: 20px;
    margin-top: 4px;
    position: relative;
    border: 1px solid #d5d6de;
    border-radius: 0;
    background: #fff;
    align-items: center;
    color: #222;
    outline: none;
    transition: all 0.3s;
}

.box textarea {
    width: 100%;
    display: flex;
    height: 100px;
    padding-top: 4px;
    padding-left: 1em;
    padding-right: 2em;
    margin-bottom: 20px;
    margin-top: 4px;
    position: relative;
    border: 1px solid #d5d6de;
    border-radius: 0;
    background: #fff;
    align-items: center;
    color: #222;
    outline: none;
    transition: all 0.3s;
    font-family: 'Amble';
}

.inputBox.large {
    grid-column: 1/3 !important;
}

.box .buttonSection{
	display: flex;
	align-items: center;
}

.box button{
    background: #27ae60 none repeat scroll 0 0;
    border: 0 none;
    border-radius: 1px;
    color: white;
    cursor: pointer;
    margin: 10px 0px;
    padding: 10px 5px;
    min-width: 100px;
    position: relative;
    font-family: 'Amble';
}

.previousButton {
    margin-right: 10px !important;
}

.box:nth-of-type(2), .box:nth-of-type(3){
	display: none;
}

.messageSuccess {
    display: block !important;
    font-size: 16px;
    text-align: center;
    background: #27ae60;
    padding: 10px;
    color: #fff;
    border-radius: 2px;
}

.messageError {
    display: block !important;
    font-size: 14px;
    text-align: center;
    background: #E06C75;
    padding: 10px;
    color: #fff;
    border-radius: 2px;
    margin-bottom: 10px;
}

.conditions {
    font-family: 'Amble';
    color: #808291;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.conditions p {
    margin-left: 10px;
}

.conditions input {
    margin-left: 10px;
}

@media screen and (max-width: 768px) {
    .content-claims {
        padding: 50px 0px 10px 0px;
    }
    .services-claims ,.claims-content__form{
        grid-template-columns: repeat(1,1fr);
    }
    .services-claims > div {
        margin-bottom: 30px;
    }
    .form-content {
        padding: 10px;
    }
}
.Menu-Category_pm, .Distributor-search {
    padding-top: 10px;
    background: #005286;
    padding-bottom: 10px;
}

.Distributor-search {
    padding-bottom: 20px;
}

.Search-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
}

.Search {
    display: grid;
    grid-template-columns: repeat(10,1fr);
    align-items: center;
    grid-gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.Search button.btn-back {
    grid-column: 2/3;
}

.Search .Search-container {
    grid-column: 3/10;
}

.Search button.btn-back {
    background: #009FE3;
    border-radius: 30px;
    border: none;
    justify-content: space-between;
    align-items: center;
    outline: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Amble';
    font-size: 14px;
    font-weight: bold;
    padding: 14px 20px;
}

.Search-container button {
    top: 5px;
    right: 5px;
    position: absolute;
    background: transparent;
    border: none;
}

.Search-container svg.search {
    position: absolute;
    left: 20px;
}

.Search-container svg.filter {
    position: absolute;
    right: 10px;
}


.Quotes-pm .Products-Quote.Search-Product .products-default .pagination {
    display: none !important;
}

.Search-container input {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #02426A;
    border-radius: 40px;
    border: none;
    padding: 15px 5px 15px 20px;
    font-family: 'Amble-light';
    font-size: 14px;
    outline: none;
}

.Search-container.lines {
    margin-bottom: 0;
}

.Search-container.lines input {
    padding: 15px 5px 15px 50px;
    margin-bottom: 30px;
}

.Search-container.lines svg.search {
    position: absolute;
    left: 20px;
    top: 15px;
}

.Search-container input::-webkit-input-placeholder {
    color: #C4C4C4;
}

.Search-container input:-ms-input-placeholder {
    color: #C4C4C4;
}

.Search-container input::placeholder {
    color: #C4C4C4;
}

.categories-products {
    display: flex;
}

.menu-categories {
    display: flex;
    width: 100%;
}

.menu-categories ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    list-style: none;
}

.menu-categories li {
    margin-bottom: 20px;
}

.menu-categories li a {
    color: #f8f8f8;
    text-decoration: none;
    font-family: 'Amble-light';
    font-size: 14px;
}

.Products-Quote_pm {
    position: relative;
    margin: 0 auto;
    background: #fff;
}

.Container-Products_Quote_pm {
    position: relative;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, 430px);
    grid-gap: 20px;
    padding-top: 20px;
    background: #fff;
}

.Products-Quote_1 {
    overflow: hidden;
    position: relative;
    grid-column: 1/6;
    width: 100%;
    border-radius: 0px 50px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.img-bioform_product {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.img-bioform_product img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.info-product_bf {
    left: 30px;
    margin-top: 50px;
    position: absolute;
    z-index: 3;
}

.info-product_bf h4{
    color: #4A7729;
    font-size: 40px;
    width: 400px;
    margin-bottom: 50px;
    font-family: 'Amble-Bold';
    font-weight: bold;
}

.info-product_bf a{
    text-decoration: none;
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #4D4D4C; 
}

.Products-Quote_2 {
    position: relative;
    grid-column: 6/9;
    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;
    border-radius: 50px 0px 0px 50px;
}

.info-product_bf_2 {
    margin-top: 50px;
    left: 40px;
    position: absolute;
    z-index: 3;
}

.info-product_bf_2 h4{
    color: #4D4D4C;
    width: 317px;
    margin-bottom: 50px;
    font-size: 40px;
    font-family: 'Amble-Bold';
    font-weight: bold;
}

.info-product_bf_2 a{
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    text-decoration: none;
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #4D4D4C; 
}

.Products-Quote_3 {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    grid-column: 1/4;
    background: #F9F8F8;
    border-radius: 0px 50px 50px 0px;
}

.info-product_bf_3 {
    width: 317px;
    margin-top: 50px;
    left: 40px;
    position: absolute;
    z-index: 3;
}

.info-product_bf_3 h4{
    color: #4c4c4c;
    font-size: 40px;
    font-family: 'Amble-Bold';
    font-weight: bold;
    margin-bottom: 50px;
}

.info-product_bf_3 a{
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    background: #fff;
    text-decoration: none;
    border: 1px solid #4D4D4C; 
}

.Products-Quote_4 {
    overflow: hidden;
    position: relative;
    grid-column: 4/9;
    border-radius: 50px 0px 0px 50px;
}


.info-product_bf_4 {
    width: 382px;
    margin-top: 50px;
    left: 40px;
    position: absolute;
    z-index: 3;
}

.info-product_bf_4 h4{
    color: #4c4c4c;
    font-size: 40px;
    font-family: 'Amble-Bold';
    font-weight: bold;
    margin-bottom: 50px;
}

.info-product_bf_4 a{
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #4D4D4C; 
}

.tree-left {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -84px;
    left: 0px;
    width: 232.98px;
    height: 284.53px;
    z-index: 3;
    background-image: url(../../static/media/fondocanaright.24584162.webp);
}

.tree-right {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    right: 0px;
    border-radius: 0px 50px 50px 0px;
    width: 232.98px;
    height: 284.53px;
    z-index: 3;
    background-image: url(../../static/media/fondocanaleft.ac1654fa.webp);
}


.bioform {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -40px;
    right: 50px;
    width: 132.98px;
    height: 184.53px;
    z-index: 3;
    background-image: url(../../static/media/bioform.c4110521.webp);
}

.pen {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -30px;
    right: 20px;
    width: 186px;
    height: 226px;
    left: -50px;
    z-index: 1;
    background-image: url(../../static/media/pen1.7e07ba8e.webp);
    opacity: 1;
    transform: rotate(121.08deg);
}

.pen_2 {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 20px;
    left: 20px;
    width: 186px;
    height: 226px;
    z-index: 1;
    background-image: url(../../static/media/pen2.1bc2d649.webp);
    opacity: 0.5;
    transform: rotate(180deg);
}

.filter-shadow {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(180deg, #FFEEE4 -55.97%, rgba(242, 226, 217, 0) 100%);
    border-radius: 0px 50px 50px 0px;
}

.filter-shadow-white {
    position: absolute;
    z-index: 2;
    width: 120%;
    height: 100%;
    top: -40px;
    left: -10px;
    background: linear-gradient(180deg, #EBF7FF 0%, rgba(235, 247, 255, 0) 106.01%);
    border-radius: 50px 0px 0px 50px;
}

.filter-shadow.blue {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 200px;
    bottom: 0;
    background: rgba(190, 230, 255, 0.5);
    background-blend-mode: multiply;
    -webkit-filter: blur(79px);
            filter: blur(79px);
    border-radius: 0px 0px 50px 0px;
}

.img-bioform_product_4 {
    display: flex;
    justify-content: flex-end;
}
.img-bioform_product_4 img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.Products-populate_pm {
    margin: 0 auto;
    background: #fff;
    padding-top: 80px;
}

.Container-products_populate .slick-slide > div > div {
    display: flex !important;
    padding-bottom: 80px !important;
    justify-content: center !important;
}

.Products-populate_pm  h4 {
    color: #005286;
    text-align: center;
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
}

.Products-populate_pm  p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4c4c4c;
    text-align: center;
    margin-top: 20px;
}

.products-populate {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.square-products {
    position: absolute;
    width: 280px;
    top: 30px;
    height: 280px;
    background: #D1EBFC;
    z-index: 1;
    border-radius: 58px;
    transform: matrix(0.91, -0.42, -0.42, -0.91, 0, 0);
}

.products-populate {
    margin-top: 40px;
    position: relative;
}

.products-populate h5 {
    position: absolute;
    top: 50px;
    color: #000;
    width: 252px;
    font-size: 22px;
    font-family: 'Amble-bold';
    left: 60px;
    z-index: 2;
}

.products-populate button{
    position: absolute;
    z-index: 3;
    font-family: 'Amble';
    background: #005286;
    cursor: pointer;
    right: 50px;
    border: 1px solid #005286;
    box-sizing: border-box;
    border-radius: 30px;
    color: #fff;
    font-size: 15px;
    padding: 10px 20px;
}

.products-populate img {
    position: relative;
    left: -40px;
    z-index: 3;
}

.Container-products_populate {
    width: 93%;
    display: flex;
    margin: 0 auto;
}

.populate-slick .slick-next {
    right: 20px !important;
    z-index: 10000 !important;
    top: 280px;
}

.populate-slick .slick-prev {
    left: -20px !important;
    z-index: 10000 !important;
    top: 280px;
}

.populate-slick .slick-next::before, .populate-slick .slick-prev::before {
    color: #fff !important;
    padding: 0px 20px;
    background: rgba(0, 82, 134, 0.6);
    border-radius: 50%;
    font-size: 40px;
    opacity: 1 !important;
    line-height: 100px;
    font-family: 'Poppins';
}

.populate-slick .slick-prev::before {
    content: '<';
}

.populate-slick .slick-next::before {
    content: '>';
}

.makeStyles-paper-1 {
    outline: none;
}

.categories-business {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.categories-business.used {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap !important;
}

.categories-business.used div {
    margin-top: 15px;
}

.business-choose {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.business-choose a {
    text-decoration: none;
    font-family: 'Amble-light';
    font-size: 13px;
    color: #4c4c4c;
    border: 1px solid #005286;
    border-radius: 20px;
    margin-bottom: 20px;
    background: #fff;
    padding: 10px 20px 10px 20px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.business-choose .active-category {
    background: #D1EBFC;
}

.business-choose a img {
    width: 16px;
    margin-right: 10px;
}

.business-choose .detail-product {
    width: 15px;
    top: 12px;
    position: absolute;
    left: 20px;
}

.categories-business svg{
    position: absolute;
    left: 20px;
    top: 10px;
}

.categories-business button{
    border: 1px solid #005286;
    box-sizing: border-box;
    border-radius: 20px;
    background: #fff;
    font-family: 'Amble';
    padding: 10px 40px 10px 50px;
    margin-right: 20px;
    outline: none;
}


.categories-business button:focus{
    outline: none;
    background: #D1EBFC;
    border: 1px solid #005286;
    box-sizing: border-box;
}

h2.title-choose_business {
    font-family: 'Amble';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
    color: #4D4D4C;
}  

.img-bioform_product_2 {
    position: relative;
    height: 100%;
    background: transparent;
}

.img-bioform_product_2 img {
    position: absolute;
    object-fit: cover;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.img-bioform_product_3 img {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    bottom: 0;
}

.Products-Quote {
    grid-column: 3/12;
    background: #fff;
    height: auto;
    padding: 20px;
    margin-bottom: 0px;
}

.Filter_pm {
    margin-top: 20px;
}

.container-filter_pm {
    display: grid;
    background: #009FE3;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 14px;
    grid-template-columns: repeat(11,1fr);
    color: #fff;
    align-items: center;
    padding: 0px 0px 0px 20px;
    font-size: 14px;
    font-family: 'Amble-light';
}

.Name-category {
    grid-column: 1/4;
    padding: 10px;
}

.Name-category p {
    font-family: 'Amble';
    font-size: 14px;
}

.img-detail_product .img-product button.slick-next , .img-detail_product .img-product button.slick-prev {
    display: none !important;
}

.Count-products {
    grid-column: 4/7;
    position: relative; 
    padding: 20px;
    height: 100%;
}

.Count-products::after {
    content: '';
    position: absolute;
    border-right: 1px solid #fff;
    height: 100%;
    right: 0;
    top: 0;
}

.Order-by {
    display: flex;
    grid-column: 7/10;
    justify-content: flex-start;
    margin-left: 20px;
}

.select-order {
    margin-left: 20px;
    font-family: 'Amble';
}

  /* .img-product .slick-prev:before, .slick-next:before {
  display: none; 
}*/

.img-product .slick-dots {
    transform: rotate(90deg);
    bottom: 100px;
    left: -200px !important;
}

.img-product .slick-dots li.slick-active button:before {
    background: #005286 !important;
}

.img-product  .slick-dots li button:before, .img-product  .slick-dots li.slick-active button:before {
    border: 2px solid #005286;
    border-radius: 50%;
}

.img-product .slick-dots li button:before {
    width: 5px !important;
    height: 5px !important;
}

.View-options {
    display: flex;
    justify-content: flex-end;
    grid-column: 10/11;
    font-family: 'Amble';
}

.products-default {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3,1fr);
}

.products-default.populate {
    grid-template-columns: none !important;
}

.products-default .square-products {
    position: absolute;
    width: 234px;
    top: 30px;
    height: 234px;
    background: #D1EBFC;
    z-index: 1;
    border-radius: 58px;
    transform: matrix(0.91, -0.42, -0.42, -0.91, 0, 0);
}

.products-default{
    margin-top: 40px;
    position: relative;
}

.products-default h5 {
    position: absolute;
    top: 25px;
    color: #000;
    width: 223px;
    font-size: 15px;
    font-family: 'Amble-bold';
    left: 0px;
    z-index: 1000;
}

.products-default .product {
    position: relative;
    width: 260px;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-default .product .img-product {
    position: absolute;
    top: 50px;
    left: 0;
    width: 223px;
}

.img-product img {
    max-width: 100%;
}

.product > a {
    position: absolute;
    z-index: 3;
    right: -70px;
    color: #fff;
    top: 140px;
    font-family: 'Amble';
    text-decoration: none;
    background: #005286;
    cursor: pointer;
    border: 1px solid #005286;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 15px;
    outline: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.product > a > a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    padding: 10px 25px;
}

.products-default img {
    position: relative;
    z-index: 3;
}

.products-default .square-products.Ecoamigable {
    background: #FCE9DE;
}

@media only screen and (max-width: 1440px) {
    .Container-header_pm .Nav-pm {
        grid-column: 3/13;
    }
    .Container-header_pm .Options-pm_qe {
        grid-column: 13/16;
    }
}

@media only screen and (max-width: 1280px) {
    .square-products ,.products-default .square-products{
        max-width: 200px;
        height: 200px;
    }
    .products-populate img {
        max-width: 200px;
    }
    .products-populate {
        margin-bottom: 80px;
    }
    .populate-slick .slick-next ,.populate-slick .slick-prev {
        top: 150px;
    }
    .product > a {
        right: -20px;
    }
    .square-sl-sbl {
        width: 487px;
        height: 343px;
        left: 190px !important;
    }
    .info-sl-sbl p {
        margin-top: 10px !important;
    }
    .square-img-card {
        right: -120px !important;
    }
    .square-pet-sbl {
        left: 60px;
    }
    .new-slick.work.sbl.community.table .slick-dots {
        left: -370px !important;
    }
    .square-pet-sbl.activity {
        left: 170px !important;
    }
    .new-slick.work.activity.tablet .slick-dots {
        left: 630px !important;
    }
}
.Distributor-pm {
    position: relative;
    background-image: url(../../static/media/distributorbanner.125ec390.webp);
    height: 50vh;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
}

.Postulation-pm {
    position: relative;
    background-image: url(../../static/media/workwithus.5139d59c.webp);
    height: 50vh;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
}

.Distributor-pm::after, .Postulation-pm::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
}

.back button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 1;
    outline: none;
    border: 1px solid #fff;
    background: rgba(118, 118, 118, 0.06);
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
    font-family: 'Amble';
    padding: 8px 20px;
    cursor: pointer;
}

.form-container input {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 40px;
    padding: 14px;
    width: 100%;
    color: rgba(77, 77, 76, 0.6);
    outline: none;
    font-size: 14px;
    font-family: 'Amble';
}

.form-container input::-webkit-input-placeholder {
    color: rgba(77, 77, 76, 0.6);
    font-family: 'Amble-light';
}

.form-container input:-ms-input-placeholder {
    color: rgba(77, 77, 76, 0.6);
    font-family: 'Amble-light';
}

.form-container input::placeholder {
    color: rgba(77, 77, 76, 0.6);
    font-family: 'Amble-light';
}

.grid-form_doble {
    grid-gap: 20px;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2,1fr);
}

.grid-form_doble_right {
    grid-gap: 20px;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(4,1fr);
}

.grid-form_triple {
    grid-gap: 20px;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(3,1fr);
}

.title-blue-container {
    margin-top: 50px;
}

.title-blue-container h3 {
    font-family: 'Amble-bold';
    color: #009FE3;
    font-size: 50px;
    width: 400px;
}

.Search-container.distributor svg {
    position: absolute;
    right: 20px;
}

.ubications {
    grid-column: 5/7;
    display: flex;
    font-family: 'Amble-bold';
    margin-left: 100px;
}

.title-ubication h6 {
    font-size: 25px;
    color: #009FE3;
}

.address-ubication, .call-ubication {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.address-ubication p, .call-ubication p {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-left: 10px;
    color: #4d4d4d;
}
.Container-sb_pm {
    margin: 0 auto;
    background: #FFF;
}

.header-title {
    margin: 0 auto;
    text-align: center;
}

.Container-sb_pm h2 {
    font-family: 'Amble-bold';
    font-size: 50px;
    color: #005286;
    width: 403px;
    margin: 0 auto;
}

.Container-sb_pm .info-sb p {
    font-family: 'Amble-light';
    font-weight: 300;
    font-size: 14px;
    color: #4D4D4C;
    margin-top: 20px;
    text-align: left;
    width: 288px;
}

.Container-sb_pm .header-title p {
    font-family: 'Amble-light';
    font-weight: 300;
    font-size: 14px;
    color: #4D4D4C;
    text-align: center !important;
    text-align: left;
    margin: 20px auto;
    width: 704px;
}

.models-sb {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    padding: 50px;
}

.model {
    width: 100%;
    width: 288px;
}

.model_1 {
    grid-column: 1/7;
}

.info-sb {
    margin: 0 auto;
}

.header-img {
    text-align: center;
    width: 240px;
}

.header-img p {
    color: #009FE3;
    font-family: 'Amble-bold';
    font-size: 16px;
    margin-top: 30px;
}

.header-img p.w-sb {
    width: 288px !important;
}

.title-container {
    color: #009FE3;
    font-family: 'Amble-bold';
    font-size: 50px;
    width: 521px;
    margin-top: 50px;
    grid-column: 2/4;
}

.objetives {
    display: flex;
    max-width: 1340px;
    align-items: center;
    flex-wrap: wrap;
    margin: 60px auto;
    justify-content: space-between;
}

.objectives-sosten {
    text-align: center;
}

.objectives-sosten p {
    margin-top: 25px;
    color: #4D4D4C;
    font-family: 'Amble-light';
    font-size: 12px;
    width: 174px;
}

.objectives-sosten span {
    color: #1FAAE5;
    font-family: 'Amble-bold';
}

.info-informs {
    grid-column: 2/4;
}

.box-informs-pdf {
    display: flex;
    align-items: center;
    background: #fff;
    width: 70%;
    padding: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 31.5px;
}

.box-informs-pdf p {
    text-decoration: underline;
    margin-left: 15px;
    font-family: 'Amble';
    color: #4D4D4C;
    font-size: 14px;
}

.info-informs h2 {
    font-size: 50px;
    font-family: 'Amble-bold';
    color: #005286;
}

.info-informs p {
    color: #4D4D4C;
    margin-top: 20px;
    width: 383px;
    font-size: 14px;
    font-family: 'Amble-light';
}

.informs-pdfs {
    grid-column: 5/8;
}

.post-sl-sbl {
    grid-column: 1/4;
    position: relative;
    display: flex;
    justify-content: center;
}

.post-img-card-rigth {
    grid-column: 5/7;
    position: relative;
    display: flex;
    justify-content: center;
}

.info-container {
    grid-column: 2/4;
    position: relative;
}

.info-container span {
    font-family: 'Amble-bold';
    color: #4D4D4C;
}

.info-container .title-green-container h6 {
    color: #8DB72E;
    font-family: 'Amble-bold';
    font-size: 30px;
}

.title-white-container h6 {
    color: #fff;
    font-family: 'Amble-bold';
    font-size: 50px;
}

.info-container .title-green-dark-container h6 {
    color: #32711E;
    font-size: 50px;
    width: 521px;
    font-family: 'Amble-bold';
}

.info-container p {
    color: #4D4D4C;
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.img-container {
    grid-column: 5/7;
}

.info-sl-sbl {
    grid-column: 5/8;
    padding: 0px 50px 50px 50px;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 400px;
}

.info-pet-sbl {
    grid-column: 5/8;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: center;
    color: #4D4D4C;
    font-family: 'Amble-light';
    right: 200px;
}

.info-pet-sbl div.bold {
    font-family: 'Amble-bold';
}

.info-sbl {
    position: absolute;
    right: -100px;
}

.info-sl-sbl img {
    display: unset !important;
    margin-bottom: 20px;
}

.info-sbl h4 {
    color: #32711E;
    font-size: 50px;
    width: 620px;
    margin-bottom: 20px;
    position: relative;
    font-family: 'Amble-bold';
}

.info-sl-sbl h6 {
    font-family: 'Amble-bold';
    color: #4D4D4C;
    font-size: 14px;
}

.info-sl-sbl p {
    font-family: 'Amble-light';
    color: #4D4D4C;
    font-size: 14px;
    margin-top: 40px;
}

.stb-slick .slick-dots {
    bottom: 10px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
    right: 0px !important;
}

.stb-slick .slick-arrow {
    display: none !important;
}

.stb-slick .slick-dots li {
    border: none !important;
}

.stb-slick .slick-dots ul li button::before {
    display: none;
}

.stb-slick .slick-dots ul li button {
    font-size: 16px !important;
    color: #8DB72E;
    font-family: 'Amble';
}

.stb-slick .slick-dots ul {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 300px;
}

.stb-slick .slick-dots ul li.slick-active, .stb-slick .slick-dots ul li {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.stb-slick .slick-dots ul li.slick-active {
    background: #8DB72E;
    border: 1px solid #8DB72E !important;
}

.stb-slick .slick-dots ul li.slick-active button {
    color: #fff;
}

.stb-slick .slick-dots ul li {
    background: transparent;
    border: 1px solid #8DB72E !important;
}

@media only screen and (max-width: 1440px) {
    .title-container {
        grid-column: 1/4;
        margin-left: 100px;
    }
    .container-grid.work {
        padding-top: 180px !important;
    }
    .post-sl-sbl {
        grid-column: 1/4;
    }
    .info-sl-sbl {
        grid-column: 5/8;
    }
    .stb-slick .slick-dots {
        right: 7px !important;
    }
    .objetives {
        max-width: 1240px;
    }
    .info-informs {
        grid-column: 1/4;
        margin-left: 100px;
    }
    .stb-slick .slick-dots ul {
        right: 90px;
    }
    .info-pet-sbl {
        grid-column: 4/8;
        right: 80px;
    }
    .post-pet-sbl {
        grid-column: 1/4;
        right: 200px;
    }
    .info-pm_work.sbl {
        margin-left: 200px !important;
    }
    .post-img-card-rigth {
        left: 140px;
    }
    .square-img-card {
        right: -60px;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 80vh;
    }
}

@media only screen and (max-width:1280px) {
    .new-slick.work.tablet .slick-dots {
        left: -380px !important;
    }
    .elipse-work {
        bottom: -450px;
    }
    .info-client-footer {
        margin-left: 180px !important;
    }
    .content-slick-new {
        height: 80vh !important;
    }
}

/* Accordiones */

.container-accordion {
    max-width: 510px;
    height: 376px;
  }
  details p {
      color: #4c4c4c !important;
      font-family: 'Amble-light';
      max-width: 440px;
      margin: 0 auto;
  }
  details {
    background: #fff;
    border-radius: 31.5px;
    color: #4c4c4c;
    font-family: 'Amble-bold';
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s;
    min-height: 48px;
    max-height: 60px;
    transform-origin: top center;
    transform: rotate(0.1deg);
    transition: all 0.3s;
    margin-bottom: 20px;
  }
  details:active {
    color: rgba(255, 255, 255, 0.9);
    border-color: #6d7380;
  }
  details ::-webkit-details-marker {
    display: none;
  }
  summary span {
      margin-left: 10px;
  }
  details + details {
    margin-top: 10px;
  }
  details p {
    color: #fefefe;
    line-height: 1.7;
    margin: 10px 0 0;
    padding: 0 20px 15px;
  }
  details.primary {
    color: #4c4c4c;
    background: #fff;
  }
  details.primary:active {
    color: rgba(255, 255, 255, 0.9);
    border-color: #0d84ff;
  }
  details.success {
    border-color: #67c23a;
    color: rgba(255, 255, 255, 0.9);
    background: #67c23a;
  }
  details.success:hover {
    border-color: #67c23a;
    color: rgba(255, 255, 255, 0.6);
  }
  details.success:active {
    color: rgba(255, 255, 255, 0.9);
    border-color: #529b2e;
  }
  details[open] {
    transition: all 0.6s;
    min-height: 100px;
    max-height: 200px;
  }
  summary {
    outline: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 13px;
  }
  summary:selection {
    background: transparent;
  }
  summary .close {
    display: none;
  }
  [open] summary .close {
    display: inline;
  }
  summary .open {
    display: inline;
  }
.Clients-pm {
    position: relative;
    z-index: 999998;
    background: #fff;
    height: auto;
    margin: 0 auto;
    padding-bottom: 80px;
}

.loading-products p {
    font-family: 'Amble-bold';
    color: #4D4D4C;
}

.slick-slide .img-client_slick img {
    width: 100px !important;
}

.bg-gray {
    position: relative;
    z-index: 999998;
    background: #F9F9F9;
    height: auto;
    margin: 0 auto;
    padding-bottom: 80px;
}

.container-clients {
    display: grid !important;
    padding: 100px 0px 0px 0px;
    grid-template-columns: repeat(7, 1fr);
}

.title-client {
    grid-column: 1/3;
    display: flex;
    margin-left: 30px;
}

.clients-page {
    max-width: 1440px;
    margin: auto;
    position: relative;
}

.info-clients {
    grid-column: 1/5;
    display: flex;
}

.info-clients h2 {
    width: 301px;
    color: #32711E;
    font-family: 'Amble-bold';
    font-size: 50px;
    margin-bottom: 30px;
}

.info-clients span {
    font-family: 'Amble-bold';
    font-size: 30px;
    color : #8DB72E;
}

.new-slick.work.tablet .slick-slide img {
    max-width: 100%;
}

.info-clients p,.info-client-footer p {
    font-family: 'Amble-light';
    font-size: 14px;
    color : #4D4D4C;
    margin-top: 30px;
    width: 301px;
}

.info-client-footer {
    max-width: 1200px;
}

.client-info {
    grid-column: 5/8;
}

.container-grid.clients-page div#industrial, .container-grid.clients-page div#foodservices {
    padding-top: 80px;
}

.container-grid.clients-page div#exportation {
    padding-top: 40px;
}

.container-grid.clients-page div#agroindustrial {
    padding-top: 40px;
}

.hide {
    display: none;
}

.info-client-footer {
    position: absolute;
    left: 0;
    bottom: -30px;
}

a.active svg circle {
    fill: #D9F0FF;
}

.info-client-footer h6 {
    margin-top: 20px;
    font-family: 'Amble-bold';
    font-size: 14px;
}

.info-client-footer p {
    margin-top: 12px !important;
}

.title-client h4 {
    width: 270px;
    font-family: Amble;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Amble-bold';
    color: #005286;
}

.title-client p {
    margin-top: 20px;
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4D4D4D;
}

.img-clients_pm  {
    grid-column: 3/8;
}

.container-clients {
    max-width: 1440px !important;
    margin: 0 auto;
}

.prs-clients {
    position: relative;
    grid-column: 5/7;
    display: flex;
}

.footer-client_main h6 {
    margin-top: 40px;
    color: #4D4D4C;
    font-family: 'Amble-light';
    font-size: 14px;
    margin-bottom: 10px;
}

.footer-client_main span {
    position: relative;
    color: #4D4D4C;
    font-family: 'Amble-bold';
    font-size: 10px;
}

.types-clients {
    margin-top: 20px;
}

.types-clients svg {
    position: relative;
    z-index: 1;
    margin-right: 15px;
}

.client-slick .slick-track .img-clients {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.client-slick .slick-track .img-clients .img-client_slick{
    border-radius: 50%;
    display: flex;
    width: 172px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 162px;
    margin-bottom: 20px;
    -webkit-filter: drop-shadow(0px 2.86917px 14.3458px rgba(216, 216, 216, 0.2));
            filter: drop-shadow(0px 2.86917px 14.3458px rgba(216, 216, 216, 0.2));
}

.client-slick .slick-next {
    right: 60px !important;
    z-index: 10000 !important;
}

.client-slick .slick-prev {
    left: 25px !important;
    z-index: 10000 !important;
}

.client-slick .slick-next::before, .client-slick .slick-prev::before {
    color: #fff !important;
    padding: 0px 20px;
    background: rgba(0, 159, 227, 0.6);
    border-radius: 50%;
    font-size: 40px;
    opacity: 1 !important;
    line-height: 100px;
    font-family: 'Poppins';
}

.client-slick .slick-prev::before {
    content: '<';
}

.client-slick .slick-next::before {
    content: '>';
}

.client-slick  {
    color: rgba(0, 159, 227, 0.6) !important;
    font-size: 50px;
    opacity: 1 !important;
}

.container-form_contact {
    display: grid;
    margin: 0 auto;
    background: #fff;
    align-items: center;
    grid-template-columns: repeat(9,1fr);
    padding-top: 50px;
}

.form-contact {
    grid-column: 2/5;
}

.form-contact h2 {
    color: #32711E;
    font-family: 'Amble-bold';
    font-size: 50px;
}

.input {
    width: 100%;
    display: block;
    margin-top: 25px;
}

.input input, .input textarea {
    padding: 12px 25px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #C4C4C4;
    font-family: 'Amble';
    outline: none;
    color: #4d4d4d;
}

.wizard-fieldset .input input,.wizard-fieldset .input textarea {
    padding: 15px 25px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #C4C4C4;
    font-family: 'Amble';
    outline: none;
    color: #4d4d4d;
}

.img-video_known .img img {
    object-fit: cover;
}

.img-company-abe {
    top: -10px;
}

.input label {
    padding: 14px 25px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #005286;
    font-family: 'Amble';
    outline: none;
    color: #005286;
    position: absolute;
    right: 0;
    font-size: 14px;
    top: 0px;
}

.input-ds {
    margin-bottom: 15px;
}

.input-ds.rt .error-ds , .input-ds .error-ds {
    position: relative;
    top: 12px;
}

.upload {
    display: none;
}

.box-container {
    overflow-x: hidden;
}

.uploader {
    padding: 10px 20px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #C4C4C4;
    font-family: 'Amble';
    outline: none;
    min-height: 53px;
    font-size: 14px;
    color: #A0A0A0;
    display: flex;
}

.uploader .input-value{
    width: 100%;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

textarea {
    border-radius: 20px;
    resize: none;
}

.input input::-webkit-input-placeholder, .input textarea::-webkit-input-placeholder {
    font-family: 'Amble-light';
}

.input input:-ms-input-placeholder, .input textarea:-ms-input-placeholder {
    font-family: 'Amble-light';
}

.input input::placeholder, .input textarea::placeholder {
    font-family: 'Amble-light';
}

.conditions {
    font-family: 'Amble-light';
    color: rgba(77, 77, 76, 0.6);
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.conditions p {
    margin-left: 10px;
}

.conditions input {
    margin-left: 10px;
}

.btn-send {
    margin-top: 30px;
    margin-bottom: 40px;
}

.btn-send button {
    font-family: 'Amble-bold';
    background: #32711E;
    padding: 10px 40px;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 30px;
}

.footer-contact {
    position: relative;
    background: #D6E3D2;
    padding: 40px;
    overflow: hidden;
}

.marker-contact {
    grid-column: 2/5;
    display: flex;
    justify-content: center;
}

.call-contact {
    grid-column: 5/7;
    display: flex;
    justify-content: center;
}

.flx {
    display: flex;
    align-items: center;
    font-family: 'Amble-bold';
    font-size: 14px;
}

.flx p {
    color: #32711E;
    margin-left: 20px;
}

.flx span {
    color: rgba(77, 77, 76, 0.6);
    margin-left: 20px;
    font-family: 'Amble-light';
}

.new-tree {
    position: absolute;
    top: -100px;
    left: 0;
}

.new-tree img {
    width: 200px;
}

.ubications {
    height: 600px;
    overflow-x: scroll;
}

@media only screen and (max-width: 1440px) {
    .info-clients {
        grid-column: 2/5;
    }
    .info-client-footer {
        margin-left: 205px;
        width: 1000px;
        bottom: -50px;
    }
    .title-client {
        grid-column: 2/3;
        margin-left: 0;
    }
    .container-clients {
        width: 100%;
    }
    .img-clients_pm {
        grid-column: 3/7;
    }
    
}

@media only screen and (max-width: 1370px) {
    .info-clients {
        grid-column: 2/5;
    }
    .info-client-footer {
        margin-left: 198px;
        width: 1000px;
        bottom: -50px;
    }
}
.complete-form {
    font-family: 'Amble-light';
    color: #4D4D4C;
    font-size: 12px;
    position: relative;
    top: 10px;
}

.content-modal {
    position: fixed;
    background: rgba(77, 77, 76, 0.35);
    z-index: 999999;
    top: 15%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-modal.gallery {
    top: 0%;
}
.content-modal.pdfs .modal-send {
    padding: 40px 20px 20px 20px !important;
    width: 600px;
    height: auto;
}
.content-modal.pdfs .info-content {
    width: 100%;
}
.content-modal.pdfs .info-content svg {
    margin-top: 0px;
}
.content-modal.pdfs h6 {
    margin-bottom: 40px;
    font-family: 'Amble-bold';
    font-size: 14px;
}
.modal-send {
    position: relative;
    max-width: 416px;
    z-index: 999999 !important;
    padding: 40px 90px 0px 90px;
    height: 253px;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 19px;
}

.modal-send.gallery {
    max-width: 800px;
    height: 470px;
    padding: 0;
}
.modal-send.gallery img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal-send .close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
}

.info-content {
    text-align: center;
}

.info-content svg {
    margin-top: 20px;
    cursor: pointer;
}

.info-content h4 {
    color: #8DB72E;
    font-family: 'Amble-bold';
    margin-top: 20px;
    font-size: 14px;
    margin-bottom: 20px;
}

.info-content p {
    font-family: 'Amble-light';
    color: #3D3D3D;
    font-size: 12px;
}

.uploader {
    position: relative;
}

.uploader label {
    cursor: pointer;
    margin: 0;
    width: 100%;
    right: 0;
}

@media only screen and (max-width: 1440px) {
    .content-modal {
        top: 5%;
    }
}
.container-detail {
    background: #fff;
    margin: 0 auto;
    padding: 40px;
}

.guide-detail_product {
    display: flex;
    align-items: center;
}

.guide-detail_product p {
    font-size: 10px;
    color: #4c4c4c;
    font-family: 'Amble-bold';
}

.guide-detail_product button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

.guide-detail_product span {
    font-size: 10px;
    margin-left: 10px;
    text-transform: uppercase;
    color: #009FE3;
    font-family: 'Amble';
}

.container-detail_product {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items: center;
}

.img-detail_product {
    position: relative;
    display: flex;
    grid-column: 1/3;
    justify-content: center;
    align-items: center;
    width: 435.96px;
    height: 372.32px;
}

.detail-info {
    grid-column: 3/5;
}

.img-detail_product .img-product {
    position: absolute;
    z-index: 2;
}

.title-detail_product h4 {
    color: #005286;
    max-width: 439px;
    font-family: 'Amble-bold';
    font-size: 30px;
}

.list-detailt_product {
    background: #FFFFFF;
    margin-top: 30px;
    max-width: 544px;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 25px;
}

.list-detailt_product ul {
    list-style: none;
}

.list-detailt_product ul li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4d4d4d;
}

.container-detail_product .square-products {
    position: absolute;
    top: 30px;
    min-width: 435.96px;
    min-height: 372.32px;
    background: #D1EBFC;
    z-index: 1;
    right: -80px;
    border-radius: 58px;
    transform: matrix(0.98, -0.22, -0.22, -0.98, 0, 0);
}
.container-detail_product .img-product {
    width: 407.34px;
    left: 90px;
    bottom: 0px;
}

.container-detail_product .img-product img {
    width: 100%;
}


.list-detailt_product ul li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4d4d4d;
}

.list-detailt_product ul li.gray {
    background: #F9F8F8;
}


.list-detailt_product ul li p{
    font-family: 'Amble';
    font-size: 14px;
    color: #000;
}

.whant-need {
    display: block;
    margin-top: 30px;
}

.whant-need label {
    width: 100%;
    font-family: 'Amble';
    font-size: 14px;
    color: #4d4d4d;
}

.whant-need input {
    margin-top: 15px;
    display: block;
    border: 1px solid #C4C4C4;
    border-radius: 40px;
    padding: 10px 60px 10px 20px;
    font-family: 'Amble-light';
    font-size: 14px;
    outline: none;
    color: #4d4d4d;
}

.whant-need input::-webkit-input-placeholder {
    color: #949090;
}

.whant-need input:-ms-input-placeholder {
    color: #949090;
}

.whant-need input::placeholder {
    color: #949090;
}

.btn-cotize button {
    background: #8DB72E;
    border-radius: 30px;
    font-family: 'Amble';
    color: #fff;
    font-size: 14px;
    margin-top: 30px;
    border: none;
    padding: 10px 30px;
    outline: none;
    cursor: pointer;
}
.Quotes-pm {
    max-width: 1440px;
    background: #fff;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
}

.Sidebar-Material_Quote {
    grid-column: 1/3;
    background: #EEEEEE;
    padding: 20px;
}

.Materials-sidebar_pm {
    margin-top: 20px;
}

.Materials-sidebar_pm p {
    font-size: 10px;
    color: #4c4c4c;
    font-family: 'Amble';
}

.Materials-sidebar_pm span {
    font-size: 11px;
    color: #4d4d4d;
    font-family: 'Amble-light';
}

.Materials-sidebar_pm h5 {
    font-size: 12px;
    margin-top: 20px;
    color: #4c4c4c;
    font-family: 'Amble';
    margin-bottom: 10px;
}

.Materials-sidebar_pm .material {
    position: relative;
    font-size: 11px;
    margin-top: 0px;
    color: #4c4c4c;
    font-family: 'Amble-light';
    padding: 0px 5px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

li a.category {
    background: #fff;
    border-radius: 30px;
    position: relative;
    color: #4D4D4C;
    padding: 15px 25px 15px 25px;
    display: flex;
    font-size: 13px;
    justify-content: center;
    align-items: center;
}

li a.active-category {
    background: #D1EBFC;
    border-radius: 30px;
    position: relative;
    color: #005286;
    font-size: 13px;
    font-family: 'Amble-bold';
    padding: 15px 25px 15px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

li a.category span, li a.active-category span {
    margin-left: 15px !important;
}

li a.category img , li a.active-category img {
    left: 10px;
    position: absolute;
}

ul li:nth-child(2) img {
    width: 23px;
}

ul li:nth-child(6) img {
    width: 14px;
}

ul li:nth-child(7) img {
    width: 16px;
}

ul li:nth-child(3) img {
    width: 20px;
}

ul li:nth-child(4) img {
    width: 25px;
}

ul li:nth-child(5) img {
    width: 25px;
}

div.checkRadioContainer > label > input {
    visibility: hidden;
}

div.checkRadioContainer {
    max-width: 10em;
}
div.checkRadioContainer > label {
    display: block;
    border: 2px solid grey;
    margin-bottom: -2px;
    cursor: pointer;
}

div.checkRadioContainer > label > span {
    display: inline-block;
    vertical-align: top;
    line-height: 2em;
}

div.checkRadioContainer > label > input + i {
    visibility: hidden;
    color: green;
    margin-left: -0.5em;
    margin-right: 0.2em;
}

div.checkRadioContainer > label > input:checked + i {
    visibility: visible;
}

.con1 {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.con1 input {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 999999;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #807f7f;
  background: #eeeeee;
  border-radius: 3px;
}

.no-quote {
    text-align: center;
}

.no-quote p {
    color: #4D4D4C;
    font-family: 'Amble-bold';
    margin-top: 70px;
    margin-bottom: 30px;
}

.content-modal.quote {
    top: 0;
}

/* When the radio button is checked, add a blue background */
.con1 input:checked ~ .checkmark {
    background-image: url(../../static/media/checkproduct.cfd567f9.webp);
    background-size: cover;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.Wrapper {
    position: relative;
    height: 100vh;
    top: 0;
    font-family: 'Amble-bold';
}

.Main-panel {
    width: 100%;
    min-height: 100vh;
    border-top: 2px solid #1B203A;
    background: #1B203A;
    transition: .5s;
    float: right;
}

.Sidebar {
    position: fixed;
    width: 230px;
    height: calc(100vh - 90px);
    margin-top: 80px;
    margin-left: 20px;
    z-index: 1024;
    border-radius: 5px;
    background: #282B49;
    transition-property: top,bottom,width;
    transition-duration: .2s,.2s,.35s;
    transition-timing-function: linear,linear,ease;
}

.img-input-ds.edit {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
}

.img-input-ds.edit img {
    height: 150px !important;
}

.Sidebar::after {
    content: "";
    position: absolute;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #282B49;
    top: -10px;
    left: 40px;
    transform: translate(-50%);
}

.Sidebar .Sidebar__Wrapper {
    min-height: 100%;
    max-height: calc(100vh - 705px);
    z-index: 4;
    overflow: hidden;
}

/* Header */
nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 25px 10px 20px;
}

.Navbar__Bar {
    position: relative;
    display: flex;
    align-items: center;
}

.Navbar__Bar button#boton-Sidebar {
    opacity: 1;
    transition: .6s;
}

.Navbar__Bar i, .Navbar__Options i {
    color: #fff;
    font-size: 20px;
    margin-left: 30px;
}

.Navbar__Bar p {
    color: #4d4d4d;
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 20px;
    opacity: 1;
    transition: .6s;
}

nav {
    position: absolute;
}

.Navbar__Bar button {
    background: transparent;
    border: none;
    outline: none;
}

.Navbar__Bar p {
    position: relative;
    font-size: 13px;
    color: #fff;
}

.Navbar__Options {
    display: flex;
    align-items: center;
}

.Navbar__Options p {
    color: #fff;
}

.Navbar__Options .Photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 30px;
    overflow: hidden;
}

.Photo img {
    width: 100%;
}

/* transisiones js */
.Navbar__Bar__fixed button{
    outline: none;
    background: transparent;
    border: none;
    font-size: 20px;
    color: #a8a8a8;
    opacity: 0;
    transition: all 0.5s;
    z-index: 100;
}

.Navbar__Bar__Fixed  {
    display: none;
    position: fixed;
    top: 30px;
}

.Navbar__Bar__Fixed.Block  {
    display: block !important;
    position: fixed;
    color: #a8a8a8;
    transition: all 0.5s;
}

.Navbar__Bar__Fixed.Align  {
    left: 20px;
}

.Navbar__Bar.none button#boton-Sidebar, .Navbar__Bar.None p {
    opacity: 0;
    transition: .5s cubic-bezier(.685,.0473,.346,1);
}

.Navbar__Bar__Fixed.block button {
    opacity: 1 !important;
    transition: all 0.5s;
}

/* Menu Sidebar*/
.Logo__Rc {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-left: 25px;
    padding-bottom: 15px;
}

.Logo__Rc::after {
    content: '';
    position: absolute;
    display: flex;
    justify-content: center;
    height: 1px;
    width: 90%;
    right: 15px;
    bottom: 0;
    background: #fff;
    z-index: 1;
}

.Logo__Rc img {
    width: 35px;
    margin-left: 22px;
}

.Logo__Rc span {
    text-transform: uppercase;
    display: block;
    white-space: nowrap;
    font-size: 13px;
    color: #fff;
    overflow: hidden;
    margin-left: 20px;
    transition: all 0.5s;
    font-weight: 300;
}

.Nav ul {
    list-style: none;
    margin-top: 20px;
}

.Nav ul li {
    padding: 20px 30px ;
}

.Nav ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.Nav ul li a i {
    color: #fff;
    font-size: 20px;
    width: 43px;
}

.Nav ul li a span{
    display: block;
    transition: 0.5s !important;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
    font-weight: 300;
    text-transform: uppercase;
}

.Block {
    animation: mostrarBotonFixed 1s;
}

@keyframes mostrarBotonFixed {
    0% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0);
    }
}

/* Contenedor principal */
.Content {
    padding: 80px 30px 30px 280px;
    transition: 0.5;
    background: #1B203A;
}

.Main {
    padding: 80px 30px 30px 130px;
    transition: 0.5;

}

.footer-dashboard {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footer-dashboard p {
    margin-top: 20px;
    color: #4d4d4d;
    font-size: 12px;
}

.Navbar__Bar button#boton-sidebar_left {
    display: none;
}

/* Animación al dar click en nuestro boton Sidebar */
.Toggle {
    width: 80px !important;
}

.Toggle .Logo__Rc {
    padding: 10px 0px 15px 0px;
}

.Toggle .Logo__Rc svg {
    width: 440px;
    margin-top: 10px;
    margin-left: 30px;
}

.Toggle .Logo__Rc::after {
    width: 70%;
    left: 15px;
}

.Toggle .Logo__Rc span {
    opacity: 0;
    transform: translate3d(-25px,0,0);
}

.Toggle .Nav ul li a span {
    opacity: 0;
    transform: translate3d(-25px,0,0);
}

/* layouts */
.content-grid-cards {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(4,1fr);
}

.content-ds-fluid {
    background: #282B49;
    border-radius: 5px;
    height: auto;
    padding: 30px 20px 30px 20px;
}

.card-ds {
    background: #282B49;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 20px;
    padding: 20px;
}

.MuiPaper-root, .MuiTableCell-stickyHeader, .MuiTableCell-root {
    background-color: transparent !important;
    font-family: 'Amble-bold' !important;
}

.body-card .img{
    display: flex;
    justify-content: space-between;
}

.body-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
}

.body-card .img {
    position: relative;
}

.body-card .img::after {
    content: '';
    height: 1px;
    position: absolute;
    z-index: 1024;
    bottom: -8px;
    left: 0;
    width: 100%;
    background: #fff;
}

.body-card p {
    margin-top: 15px;
}

.title-content-ds {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-content-ds h6 {
    color: #fff;
    font-size: 16px;
}


.title-content-ds a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    background: #F65252;
    border-radius: 5px;
    border: none;
    padding: 10px;
    outline: none;
    cursor: pointer;
}

.img-container-ds img {
    width: 400px;
    height: 300px;
}

.grid-container-ds {
    margin-top: 40px;
    grid-template-columns: repeat(3,1fr);
    display: grid;
    grid-gap: 50px;
}

.gallery-items {
    position: relative;
}

.gallery-items p , .gallery-items span {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    font-family: 'Amble-light';
}

.gallery-items p {
    top: 35px;
}

.gallery-items::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 5px;
}

.gallery-items img {
    width: 100%;
    border-radius: 5px;
}

.form-grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    margin-top: 20px;
    grid-column-gap : 30px;
}

.input-ds label {
    color: #fff;
    font-weight: 300;
    line-height: 30px;
    font-size: 12px;
}

.input-ds input {
    background: transparent;
    border: 1px solid rgb(128, 116, 116);
    padding: 10px 12px;
    border-radius: 5px;
    outline: none;
    width: 100%;
    font-family: 'Amble-bold';
    color: #fff !important;
}

.input-ds textarea {
    border: 1px solid rgb(128, 116, 116);
    background: transparent;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
    font-family: 'Amble-bold';
}

.input-ds input::focus {
    border: 1px solid rgb(46, 138, 158) !important;
}


.img-input-ds img {
    border-radius: 5px;
    height: 500px;
    margin-bottom: 30px;
}

.error-ds {
    color: #FD6D86;
    font-size: 12px;
    position: relative;
    top: -6px;
}

.form-grid button {
    background: #F65252;
    outline: none;
    border: none;
    padding: 10px;
    font-family: 'Amble-bold';
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.grid-container-ds .card-work {
    margin-left: 0;
    width: 100% !important;
    height: 540px;
}

.grid-container-ds .card-work img {
    width: 100%;
    height: 470px;
    object-fit: cover;
}

.grid-container-ds .card-work .card-body {
    padding: 20px 25px 0px 35px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.gallery-items .delete {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: #1B203A;
    border-radius: 50%;
    cursor: pointer !important;
    padding: 8px 10px;
    z-index: 3000;
    outline: none;
}

.gallery-items .edit {
    position: absolute;
    right: 60px;
    top: 10px;
    border: none;
    background: #1B203A;
    border-radius: 50%;
    cursor: pointer !important;
    padding: 8px 10px;
    z-index: 3000;
    outline: none;
}

.gallery-items .delete svg {
    fill: #F65252;
}

.success-image p {
    color: #55F09D;
    font-size: 12px !important;
}

.error-image p {
    color: #F34F6B;
    font-size: 12px !important;
}

.input-ds .flex-popular input {
    margin-bottom: 0px !important;
}

.flex-popular {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.images-adds {
    margin-top: 30px;
    align-items: center;
    display: flex;
    color: #fff;
    justify-content: space-between;
}

.gallery-items .edit svg {
    fill: #55F09D;
}

.success-alert {
    background: #55F09D;
    color: #fff;
    position: absolute;
    padding: 10px;
    border-radius: 5px;
    top: 30px;
    right: 30px;
}

.ql-editor {
    font-family: 'Amble-bold';
}

.ql-toolbar.ql-snow svg {
    fill: #fff !important;
}

.ql-snow .ql-stroke {
    stroke: #fff !important;
}

.ql-toolbar.ql-snow .ql-picker-label {
    color: #fff;
}

.card-button {
    display: flex;
    justify-content: space-between;
}

.card-button .delete {
    border: 1px solid #FD6D86;
    color: #FD6D86;
}

.input-ds .css-1uccc91-singleValue {
    color: #fff;
}

.container-grid-ds-forms {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
}

.container-grid-ds-forms.doble {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 20px;
}

.grid-container-ds .gallery-items p {
    width: 72%;
}

@media only screen and (max-width: 1440px) {
    .grid-container-ds {
        grid-template-columns: repeat(2,1fr);
    }
}

@media only screen and (max-width: 1200px) {
    .Sidebar {
        position: fixed;
        display: block;
        top: 0;
        height: 100vh;
        width: 260px!important;
        right: auto;
        left: 0;
        margin: 0;
        border-radius: 0;
        z-index: 1032;
        visibility: visible;
        overflow-y: visible;
        padding: 0;
        transition: .5s cubic-bezier(.685,.0473,.346,1);
        transform: translate3d(-260px,0,0);
    }
    .Content {
        padding: 80px 30px 30px 30px;
        transition: 0.5;
        background: #1B203A;
    }
    .Sidebar.Toggle {
        transition: 0.5;
        z-index: 1000;
    }
    .Sidebar__Wrapper {
        transition-property: top,bottom,width;
        transition-duration: .2s,.2s,.35s;
        transition-timing-function: linear,linear,
    }
    .Main__Panel > .Content {
        padding-left: 30px;
    }
    .Navbar__Bar__Fixed button {
        display: none;
    }
    .Navbar__Bar button#boton-sidebar {
        display: none;
    }
    .Navbar__Bar button#boton-sidebar_left {
        display: block;
    }    
    .Nav-open .Sidebar {
        transition: .5s cubic-bezier(.685,.0473,.346,1);
        transform: translateZ(0);
    }
    .Nav-open .Main-panel {
        right: 0;
        transform: translate3d(260px,0,0);
        transition: .5s cubic-bezier(.685,.0473,.346,1);
    }
    .Toggle .Logo__Rc span {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    .Toggle .Nav ul li a span {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

@media only screen and (max-width: 998px) {
    .grid-row_4 {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
    }
    .grid-row_charts {
        grid-template-columns: repeat(1,1fr);
    }
}

@media only screen and (max-width: 768px) {
    .grid-row_4 {
        grid-template-columns: repeat(1,1fr);
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.content-login {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    font-family: 'Roboto';
}

.modal-container {
    border-radius: 20px;
    max-width: 1200px;
    margin: 80px auto;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3),0 -6px 16px -6px rgba(0,0,0,0.025);;
}

.banner-container {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 40px;
    display: block;
    justify-content: center;
    grid-column: 1/4;
    background-image: linear-gradient(to right, #1C73E7, #1C73E7, #1C73E7);;
}

.img-container {
    display: flex;
    justify-content: center;
}

.banner-container > p {
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
}

.info-banner {
    text-align: center;
    margin-top: 30px;
}

.info-banner p {
    color: #fff;
    font-weight: 400;
}

.form-container {
    padding: 90px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #fff;
    grid-column: 4/6;
}

.form-container h2 { 
    color: #3F3E55;
    line-height: 1.4;
}

.welcome-info {
    margin-top: 10px;
}

.info-form {
    margin-top: 30px;
}

.info-form p {
    color: #3F3E55;
    line-height: 1.6;
}

form {
    margin-top: 40px;
}

.input-div {
    position: relative;
    display: grid;
    grid-template-columns: 8% 92%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.input-div.one {
    margin-top: 20px;
}

.input-div.two {
    margin-bottom: 4px;
}

.icono {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icono i {
    color: #d9d9d9;
    transition: .3s;
}

.input-div > div {
    position: relative;
    height: 40px;
}

.input-div > div h5 {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #999;
    font-size: 14px;
    font-weight: 300;
    transition: .3s;
}

.input-div >div > input {
    position: absolute;
    width: 100%;
    top: -22px;
    left: 0;
    border: none;
    outline: none;
    background: none;
    padding: 0.5em 0.7rem;
    font-size: 1rem;
    color: #3F3E55;
}

.btn {
    margin-top: 40px;
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    color: #fff !important;
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
    border: none !important;
    background-image: linear-gradient(to right, #1C73E7, #1C73E7, #1C73E7);;
    background-size: 200%;
    transition: .5s;
}

.btn:hover {
    background-position: right;
}

.input-div.focus .icono i {
    color: #1C73E7;
}

.input-div.focus div h5 {
    top: -5px;
    font-size: 13px;
}

.input-div::after, .input-div::before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background-color: #1C73E7;
    transition: .4s;
}

.input-div::before {
    right: 50%;
}

.input-div::after {
    left: 50%;
}

.input-div.focus::after, .input-div.focus::before {
    width: 50%;
}

@media screen and (max-width: 1280px) {
    .modal-container {
        grid-template-columns: 1fr;
    }
    .banner-container {
        display: none;
    }
    .form-container {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
    }
}

@media screen and (max-width: 768px) {
    .modal-container {
        max-width: 500px;
    }
    .form-container {
        padding: 50px;
    }
}

@media screen and (max-width:500px) {
    .modal-container {
        max-width: 350px;
    }   
    .form-container {
        padding: 30px;
    }
}
.pagination {
    margin: 15px auto;
    padding: 70px 0;
    display: flex;
    justify-content: center;
    list-style: none;
    outline: none;
  }
  .pagination > li > a{
    border-radius: 50%;
    font-family: 'Amble';
    outline: none;
    cursor: pointer;
    padding: 10px 15px;
    outline: none ;
    border-radius: 50% !important;
    color: #fff;
    background: #009FE3;
    margin-left: 10px;
  }
.form-wizard {
  color: #888888;
  margin-top: 80px;
}

.form-wizard .wizard-form-radio {
  display: block;
  margin-left: 5px;
  position: relative;
}

.form-wizard .wizard-form-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #dddddd;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.form-wizard .wizard-form-radio input[type="radio"]:focus {
  outline: 0;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked {
  background-color: #fb1647;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  left: 1px;
  right: 0;
  margin: 0 auto;
  top: 8px;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::after {
  content: "";
  display: inline-block;
  animation: click-radio-wave 0.65s;
  background: #000000;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}

.form-wizard .wizard-form-radio input[type="radio"]~label {
  padding-left: 10px;
  cursor: pointer;
}

.form-wizard .form-wizard-header {
  text-align: center;
  position: relative;
  width: 100%;
  right: 0%;
  margin: 0 auto;
  top: -45px;
}

.form-wizard .form-wizard-next-btn {
  background: #8DB72E;
  color: #ffffff;
  width: 100%;
  border-radius: 30px;
  text-decoration: none;
  padding: 13px 45px;
  text-align: right;
}

.quote-back {
  background: #8DB72E;
  color: #ffffff;
  width: 100%;
  border-radius: 30px;
  text-decoration: none;
  padding: 13px 45px;
  font-family: 'amble-light';
  text-transform: capitalize;
}

.form-wizard .wizard-fieldset {
  display: none;
}

.form-wizard .wizard-fieldset.show {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  border: none;
  font-family: 'Amble';
}

.form-wizard .wizard-form-error {
  display: none;
  background-color: #d70b0b;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
}

ul.list-unstyled {
  list-style: none;
}

.form-wizard .form-wizard-previous-btn {
  background-color: #009FE3;
  color: #ffffff;
  width: 100%;
  margin-right: 20px;
  border-radius: 30px;
  text-decoration: none;
  padding: 13px 45px;
  text-align: right;
}

button.send-cart {
  background-color: #009FE3;
  color: #ffffff;
  border-radius: 30px;
  text-decoration: none;
  padding: 13px 45px;
  text-align: right;
  font-family: 'Amble';
  font-size: 16px;
  border: none;
  cursor: pointer;
}

button.send-cart.complete {
  background-color: #32711E;
  color: #ffffff;
  border-radius: 30px;
  text-decoration: none;
  padding: 13px 45px;
  text-align: right;
  font-family: 'Amble';
  font-size: 16px;
  border: none;
  cursor: pointer;
}

button.send-cart__incomplete {
  background-color: #82A776;
  color: #ffffff;
  border-radius: 30px;
  text-decoration: none;
  padding: 13px 45px;
  text-align: right;
  font-family: 'Amble';
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.form-wizard .form-control {
  font-weight: 300;
  height: auto !important;
  padding: 15px;
  color: #888888;
  background-color: #f1f1f1;
  border: none;
}

.form-wizard .form-control:focus {
  box-shadow: none;
}

.form-wizard .form-group {
  position: relative;
  margin: 35px 0;
  text-align: end;
}

.form-wizard .wizard-form-text-label {
  position: absolute;
  left: 10px;
  top: 16px;
  transition: 0.2s linear all;
}

.form-wizard .focus-input .wizard-form-text-label {
  color: #d65470;
  top: -18px;
  transition: 0.2s linear all;
  font-size: 12px;
}

.form-wizard .form-wizard-steps {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.form-wizard .form-wizard-steps li {
  width: 25%;
  float: left;
  position: relative;
}

.form-wizard .form-wizard-steps li::after {
  background-color: #f3f3f3;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  border-bottom: 1px solid #dddddd;
}

.form-wizard .form-wizard-steps li span {
  background-color: #c4c4c4;
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  line-height: 40px;
  position: relative;
  text-align: center;
  width: 25px;
  z-index: 1;
}

.form-wizard .form-wizard-steps li:last-child::after {
  width: 50%;
}

.form-wizard .form-wizard-steps li.active span,
.form-wizard .form-wizard-steps li.activated span {
  background: #009FE3;
  color: #ffffff;
}

.form-wizard .form-wizard-steps li.active::after,
.form-wizard .form-wizard-steps li.activated::after {
  left: 50%;
  width: 50%;
}

.form-wizard .form-wizard-steps li:last-child::after {
  left: 0;
}

.form-wizard .wizard-password-eye {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.quote-count {
  display: flex;
  background: #8DB72E;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
}

.quote-count .quote-title {
  display: flex;
  align-items: center;
}

.quote-count .quote-title h6 {
  color: #fff;
  font-size: 25px;
}

.quote-title span {
  font-family: 'amble-light';
  color: #fff;
  margin-left: 40px;
}

.box-quote {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
  border-radius: 14px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-top: 20px;
}

.step-final .info-box-quote {
  grid-column: 1/8;
}

.step-final .delete-quote {
  grid-column: 8/9;
}

.info-box-quote {
  display: flex;
  height: 200px;
  align-items: center;
  grid-column: 1/6;
}

.info-box-quote img {
  width: 100%;
}

.info-box-quote .info {
  text-align: left;
}

.info-box-quote .img-quote {
  max-width: 180px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.delete-quote {
  grid-column: 6/9;
}

.info-box-quote .info h3 {
  color: #000;
  font-size: 20px;
}

.info-box-quote .info p {
  font-size: 14px;
  color: #4D4D4C;
  font-family: 'amble-light';
}

.delete-quote {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 20px;
}

.delete-quote button {
  background: transparent;
  display: flex;
  align-items: center;
  border: none;
  text-decoration: underline;
  color: #949491;
  font-size: 10px;
}

ul.form-wizard-steps span.quote::after {
  position: absolute;
  content: attr(data-content);
  color: #4D4D4C;
  top: 20px;
  right: -14px;
  font-family: 'amble-light';
  font-size: 13px;
}

ul.form-wizard-steps span.quote-aditional::after {
  position: absolute;
  content: attr(data-content);
  color: #4D4D4C;
  width: 400px;
  top: 20px;
  right: -190px;
  font-family: 'amble-light';
  font-size: 13px;
}

.container-send-quote {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.container-dates-title {
  display: flex;
  background: #DADADA;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
}

.container-dates {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
  border-radius: 14px;
}

.container-dates-title h6 {
  font-family: 'Amble';
  font-size: 25px;
  color: #4D4D4C;
}

.container-form-dates {
  padding: 10px 20px 40px 20px;
}

.input p {
  color: rgba(77, 77, 76, 0.6) !important;
  font-family: 'Amble-bold' !important;
}

@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative;
  }

  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0.0;
  }
}

@media screen and (max-width: 767px) {
  .wizard-content-left {
    height: auto;
  }
}
